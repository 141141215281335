<nz-card>

    <div class="row justify-content-between">
        <div class="col-lg-8 col-md-4 col-sm-5 col-12 mb-3">
            <div class="d-md-flex">
                <h2> Product List</h2>
            </div>
        </div>
        <div class="col-lg-4 col-sm-5 col-12 text-right">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" [(ngModel)]="searchFilter" (keyup)="clearCheck()"
                        (keyup.enter)="searchProducts()" nz-input placeholder="search by product name" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i (click)="searchProducts()" nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>
    </div>

    <!-- <nz-table #productsListTable [nzData]="productsList" [nzShowPagination]="true">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsList;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemCode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <a (click)="showMedia(item)" nz-button nzType="" class="add-button">
                            <span>Upload</span>
                        </a>
                    </div>
                </td>
                <td>
                    <nz-badge *ngIf="item.active == 'active'" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.active == 'inactive'" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.active}}</span>
                </td>
            </tr>
        </tbody>
    </nz-table> -->


    <nz-table #productsListTable [nzData]="productsList" [nzFrontPagination]="false" [nzTotal]="totalCount"
        [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('productsTable' , $event)"
        [nzScroll]="{x: '900px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="productsTable" *ngFor="let item of productsList;  let i=index">
                <td>{{((pageIndex-1) * pageSize) + (i+1)}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemCode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <a (click)="showMedia(item)" nz-button nzType="" class="add-button">
                            <span>Upload</span>
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- MODAL SECTION STARTS -->

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="AddImage" nzTitle="Add Image"
    (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-offer-item></app-add-offer-item> -->
        <!-- ADD PRODUCTS FORM -->

        <!-- <div class="container"> -->
        <div class="row main-row">
            <div class="col-lg-10">
                <form nz-form>

                    <!-- <div class="row"> -->
                    <!-- <div class="col-lg-6"> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Item code</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Item code">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- </div> -->

                    <!-- <div class="col-lg-6"> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Product Name</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Product Name">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- </div> -->

                    <!-- <div class="col-lg-6"> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandLogo">Image</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <button type="button" nz-button nzType="default"
                                    (click)="showMedia()"><span>Upload</span></button>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- </div> -->

                    <!-- <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Active</nz-form-label>
                <nz-form-control>
                    <nz-input-group>
                        <nz-switch id="BrandActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> -->

                    <!-- <div class="col-lg-6"> -->
                    <!-- <nz-form-item class="text-center">
                <nz-form-control>
                    <button (click)="resetForm()" type="reset" class="mr-5" nz-button nzType="default">Reset</button>
                    <a><button  nz-button nzType="primary">Add</button></a>
                </nz-form-control>
            </nz-form-item> -->
                    <!-- </div> -->
                    <!-- </div> -->

                </form>

            </div>
        </div>
        <!-- </div> -->




    </div>
</nz-modal>

<!-- MODAL SECTION FOR MEDIA PAGE -->

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image"
    (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="media-section container-fluid" *nzModalContent>
        <app-media-page></app-media-page>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">Back</button>
        <button nz-button nzType="primary" (click)="sendMedia()" [nzLoading]="isOkLoading">Update</button>
    </div>
</nz-modal>

<!-- (nzOnOk)="sendMedia()" -->

<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>