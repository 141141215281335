<div class="row main-row">
    <div class="col-lg-10">
        <form nz-form>


            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}" (keyup)="sendUserData()" nz-input placeholder="Enter User Name">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Mobile Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="number" [(ngModel)]="mobNo" [ngModelOptions]="{standalone: true}" (keyup)="sendUserData()" nz-input placeholder="Enter Mobile Number">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Email Id</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" (keyup)="sendUserData()" nz-input placeholder="Enter Email">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Role</nz-form-label>
                <nz-form-control>
                    <nz-input-group>
                        <nz-select nzShowSearch [(ngModel)]="role" [ngModelOptions]="{standalone: true}" (ngModelChange)="sendUserData()" class="w-100" nzPlaceHolder="None">
                            <nz-option nzValue="Select" nzLabel="Select"></nz-option>
                            <nz-option nzValue="Super Admin" nzLabel="Super Admin"></nz-option>
                            <nz-option nzValue="Sub Admin" nzLabel="Sub Admin"></nz-option>
                            <nz-option nzValue="Offer Maker" nzLabel="Offer Maker"></nz-option>
                            <nz-option nzValue="Offer Admin" nzLabel="Offer Admin"></nz-option>
                            <nz-option nzValue="CSR (Customer Service Representatives)" nzLabel="CSR"></nz-option>
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Location</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" [(ngModel)]="location" [ngModelOptions]="{standalone: true}" (keyup)="sendUserData()" nz-input placeholder="Enter Location">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <!-- <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Status</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Status">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> -->

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Password</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" [(ngModel)]="password" (keyup)="sendUserData()" [ngModelOptions]="{standalone: true}" nz-input placeholder="Password">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Active</nz-form-label>
                <nz-form-control>
                    <nz-input-group>
                        <nz-switch id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}" (ngModelChange)="sendUserData()" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

        </form>

    </div>
</div>