import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import { el } from 'date-fns/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavService } from 'src/app/services/nav.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

interface DataItem {
  id: any;
  offerName: any;
  itemCode: any;
  itemName: string;
  productImage: any; //data.images[0].image.url,
  offerStart: any;
  offerEnd: any;
  totalMrp: any;
  totalOfferPrice: any;
  offerPercentage: any;
  isPublished: any;
  productId: any;
  isSelected: any;
}

@Component({
  selector: 'app-single-product-offer-page',
  templateUrl: './single-product-offer-page.component.html',
  styleUrls: ['./single-product-offer-page.component.css'],
})
export class SingleProductOfferPageComponent implements OnInit {
  PublishStatus = false;
  MediaPage = false;
  isOkLoading = false;
  imageId = '';
  searchFilter = '';
  productId = '';
  checkboxActive = false;
  getscreenwidth: any;

  Checked = false;
  tableWidth = '';

  selectedOption = 'Publish Selected';

  checkedList = [];

  orderColumn = [
    // {
    //   width: '80px',
    //   title: 'Select',
    // },
    {
      width: '80px',
      title: 'S.NO',
    },

    {
      width: '150px',
      title: 'Offer Name',
      // compare: (a: DataItem, b: DataItem) => a.offerName - b.offerName,
    },
    {
      width: '150px',
      title: 'Item code',
      // compare: (a: DataItem, b: DataItem) => a.itemCode - b.itemCode,
    },
    {
      width: '150px',
      title: 'Item Name',
      compare: (a: DataItem, b: DataItem) =>
        a.itemName.localeCompare(b.itemName),
    },
    {
      width: '150px',
      title: 'Status',
      // compare: (a: DataItem, b: DataItem) => a.isPublished - b.isPublished,
    },
    {
      width: '150px',
      title: 'Product Image',
      // compare: (a: DataItem, b: DataItem) => a.productImage - b.productImage,
    },
    {
      width: '150px',
      title: 'Start date',
      compare: (a: DataItem, b: DataItem) =>
        a.offerStart.localeCompare(b.offerStart),
    },
    {
      width: '150px',
      title: 'End date',
      compare: (a: DataItem, b: DataItem) =>
        a.offerEnd.localeCompare(b.offerEnd),
    },
    {
      width: '150px',
      title: 'Mrp Price (Rs.)',
      // compare: (a: DataItem, b: DataItem) => a.totalMrp - b.totalMrp,
    },
    {
      width: '150px',
      title: 'Offer Price (Rs.)',
      // compare: (a: DataItem, b: DataItem) => a.totalOfferPrice - b.totalOfferPrice,
    },
    {
      width: '180px',
      title: 'Discount Percentage (%)',
      // compare: (a: DataItem, b: DataItem) => a.offerPercentage - b.offerPercentage,
    },
    // {
    //   width: '150px',
    //   title: 'Action',
    // },
  ];

  productsList: DataItem[] = [];

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  fetchPublishStatus() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    axios
      .get(environment.url + 'settings', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data.settings[0].publishOffers.spo);
        this.PublishStatus = response.data.data.settings[0].publishOffers.spo;
        this.spinner.hide();
        // this.productsList = response.data.data.map(
        //   (data: any, index: number) => {
        //     return {
        //       id: data._id,
        //       offerName: data.offerName,
        //       itemCode: data.itemCode,
        //       itemName: data.itemName,
        //       productImage:
        //         data.isProductHasImage == true
        //           ? environment.url + '' + data.image[0].image
        //           : undefined, //data.images[0].image.url,
        //       offerStart: data.offerStartDate.split('T')[0],
        //       offerEnd: data.offerEndDate.split('T')[0],
        //       totalMrp: data.mrpPrice,
        //       totalOfferPrice: data.promotionalPrice,
        //       offerPercentage: data.discPercentage,
        //       isPublished: data.isPublished,
        //     };
        //   }
        // );
        //this.attributeList = response.data.data;
        // console.log(this.productsList);
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }
  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    this.spinner.show();
    axios
      .get(
        environment.url +
          'spo?page=' +
          this.pageIndex +
          '&search=' +
          this.searchFilter,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.totalCount = response?.data?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.data?.pagination?.limit;
        this.productsList = response.data.data.singleProductOffer.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              offerName: data.offerName,
              itemCode: data.itemCode,
              itemName: data.itemName,
              productImage:
                data.isProductHasImage == true
                  ? environment.url + '' + data.image[0].image
                  : undefined, //data.images[0].image.url,
              offerStart: data.offerStartDate.split('T')[0],
              offerEnd: data.offerEndDate.split('T')[0],
              totalMrp: data.mrpPrice,
              totalOfferPrice: data.promotionalPrice,
              offerPercentage: data.discPercentage,
              isPublished: data.isPublished,
              productId: data.item[0]._id,
              isSelected: false,
            };
          }
        );
        //this.attributeList = response.data.data;
        console.log(this.productsList);
        this.fetchPublishStatus();
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }
  // productsList = [
  //   {
  //     offerName:'Ramzan offer',
  //     itemCode:'#123456',
  //     itemName:'Gold Winner Refined Sunflower Oil Pouch',
  //     productImage:'../../../assets/AdminPanel/Media/Image 15.png',
  //     offerStart:'dd/mm/yyyy',
  //     offerEnd:'dd/mm/yyyy',
  //     totalMrp:'77.99',
  //     totalOfferPrice:'70.99',
  //     offerPercentage:'15.00',
  //   },
  //   {
  //     offerName:'Ramzan offer',
  //     itemCode:'#123456',
  //     itemName:'Gold Winner Refined Sunflower Oil Pouch',
  //     productImage:'../../../assets/AdminPanel/Media/Image 11.png',
  //     offerStart:'dd/mm/yyyy',
  //     offerEnd:'dd/mm/yyyy',
  //     totalMrp:'77.99',
  //     totalOfferPrice:'70.99',
  //     offerPercentage:'15.00',
  //   },
  //   {
  //     offerName:'Ramzan offer',
  //     itemCode:'#123456',
  //     itemName:'Gold Winner Refined Sunflower Oil Pouch',
  //     productImage:'../../../assets/AdminPanel/Media/Image 10.png',
  //     offerStart:'dd/mm/yyyy',
  //     offerEnd:'dd/mm/yyyy',
  //     totalMrp:'77.99',
  //     totalOfferPrice:'70.99',
  //     offerPercentage:'15.00',
  //   },

  // ];

  constructor(
    public navService: NavService,
    private spinner: NgxSpinnerService,
    private transferState: TransferState,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  syncProducts() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    axios
    .post(environment.url + 'spo/sync-sps', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    .then((response) => {
      this.toastr.success('', response.data.message, {
        timeOut: 2500,
      });
      // this.productsList = response.data.data.map(
      //   (data: any, index: number) => {
      //     return {
      //       id: data._id,
      //       offerName: data.offerName,
      //       itemCode: data.itemCode,
      //       itemName: data.itemName,
      //       productImage:
      //         data.isProductHasImage == true
      //           ? environment.url + '' + data.image[0].image
      //           : undefined, //data.images[0].image.url,
      //       offerStart: data.offerStartDate.split('T')[0],
      //       offerEnd: data.offerEndDate.split('T')[0],
      //       totalMrp: data.mrpPrice,
      //       totalOfferPrice: data.promotionalPrice,
      //       offerPercentage: data.discPercentage,
      //       isPublished: data.isPublished,
      //     };
      //   }
      // );
      //this.attributeList = response.data.data;
      // console.log(this.productsList);
    })
    .catch((error) => {
      console.log(error);
      this.spinner.hide();
      //alert(error.response.data.message);
    });
  }

  ngOnInit(): void {
    this.pageIndex = 1;
    this.fetchData();
    this.getscreenwidth = window.innerWidth;
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }
  changeStatus(item) {
    //alert(item.id);
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    var reqObj = {
      spoId: item.id,
      isPublished: !item.isPublished,
    };
    alert(JSON.stringify(reqObj));
    axios
      .post(environment.url + 'spo/toggle/', reqObj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.fetchData();

        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  publishAll() {
    //this.PublishStatus = !this.PublishStatus;
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    var reqObj = {
      spoIds: this.checkedList,
      isPublished: this.selectedOption,
    };
    axios
      .post(environment.url + 'spo/toggle-selected-products/', reqObj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.checkboxActive = false;
        this.Checked = false;
        this.checkedList = [];
        this.fetchData();

        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  handleCancel() {
    this.MediaPage = false;
  }

  showMedia(productId) {
    this.productId = productId;
    this.MediaPage = true;
  }

  sendMedia() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.imageId = item;
    });
    var imageDetils = {
      productId: this.productId,
      imageId: this.imageId,
    };
    axios
      .post(environment.url + 'products/update-image', imageDetils, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.handleCancel();
        this.fetchData();
        // this.productsList = response.data.data;
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
    this.handleCancel();
  }

  checkSelected(item) {
    // alert('Checkbox works');

    item.isSelected = !item.isSelected;
    // console.log(item.isSelected);
    if (item.isSelected == true) {
      this.checkedList.push(item.id);
      // console.log(this.checkedList);
    } else if (item.isSelected == false) {
      this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
      // console.log(this.checkedList);
    }

    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  SelectAll() {
    // alert(this.Checked);
    for (let i = 0; i < this.productsList.length; i++) {
      if (this.Checked == false) {
        this.productsList[i].isSelected = true;
        this.checkedList.push(this.productsList[i].id);
      } else if (this.Checked == true) {
        this.productsList[i].isSelected = false;
        this.checkedList = [];
      }
    }
    // console.log(this.checkedList);
    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
      this.spinner.show();
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
      window.scroll(0, 0);
    }
  }

  searchProducts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  clearCheck() {
    if (!this.searchFilter) {
      this.searchProducts();
    }
  }
}
