import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NavService } from '../../services/nav.service';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-banner-page',
  templateUrl: './banner-page.component.html',
  styleUrls: ['./banner-page.component.css'],
})
export class BannerPageComponent implements OnInit {
  AddBanner = false;
  MediaPage = false;
  isOkLoading = false;
  showLinkDropdown = false;

  getscreenwidth: any;
  tableWidth = '';

  searchFilter = '';
  BannerList = [];
  bannerType = '';
  isActive = false;
  imageId = '';
  id = '';
  isEdit = false;
  Link: any = '';

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  fetchData() {
    var self = this;
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    self.spinner.show();
    axios
      .get(environment.url + 'banner/', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.BannerList = response.data.data.allBanners.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              type: data.bType,
              link: data.link,
              image: environment.url + '' + data.image.image, //data.images[0].image.url,
              active: data.isActive,
              imageid: data.image._id,
            };
          }
        );
        self.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  orderColumn = [
    {
      width: '50px',
      title: 'S.No',
    },
    {
      width: '150px',
      title: 'Type',
    },
    {
      width: '150px',
      title: 'Image',
    },
    {
      width: '150px',
      title: 'Active',
    },
    // {
    // width:'150px',
    //   title: 'Location',
    // },
    {
      width: '150px',
      title: 'Action',
    },
  ];

  constructor(
    public navService: NavService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.fetchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  handleCancel() {
    //this.AddBanner = false;
    this.MediaPage = false;
  }

  handleClose() {
    this.AddBanner = false;
  }
  showMedia() {
    this.MediaPage = true;
  }

  sendMedia() {
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.imageId = item;
    });
    this.handleCancel();
  }

  doChanges() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    if (self.bannerType == '') {
      self.toastr.error('', 'Select Banner Type', {
        timeOut: 2500,
      });
    } else if (self.imageId == '') {
      self.toastr.error('', 'Select Banner Image', {
        timeOut: 2500,
      });
    } else {
      self.isOkLoading = true;
      if (self.isEdit == false) {
        var bannerDetails: any = {
          bType: self.bannerType,
          image: self.imageId,
          isActive: self.isActive,
        };
        if (self.bannerType == 'product' || self.bannerType == 'category') {
          bannerDetails.link = self.Link;
        }
        axios
          .post(environment.url + 'banner/create', bannerDetails, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              self.fetchData();
              self.AddBanner = false;
              self.toastr.success('', response.data.message, {
                timeOut: 2500,
              });
              self.isOkLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
            self.isOkLoading = false;
            let errorData = error.response.data.data;
            let errorString = 'Please Check Below Error \n\n';
            if (errorData) {
              for (let i = 0; i < errorData.length; i++) {
                errorString +=
                  errorData[i].param + ' - ' + errorData[i].msg + '\n';
              }
              alert(errorString);
            }
          });
      } else {
        var updateDetails: any = {
          id: self.id,
          bType: self.bannerType,
          image: self.imageId,
          isActive: self.isActive,
        };
        if (self.bannerType == 'product' || self.bannerType == 'category') {
          updateDetails.link = self.Link;
        }
        axios
          .post(environment.url + 'banner/update', updateDetails, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              self.fetchData();
              self.AddBanner = false;
              self.isOkLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
            self.isOkLoading = false;
            let errorData = error.response.data.data;
            let errorString = 'Please Check Below Error \n\n';
            if (errorData) {
              for (let i = 0; i < errorData.length; i++) {
                errorString +=
                  errorData[i].param + ' - ' + errorData[i].msg + '\n';
              }
              alert(errorString);
            }
          });
      }
    }
  }

  EditUser(item: any) {
    console.log(item);
    var self = this;
    self.AddBanner = true;
    self.isEdit = true;
    self.bannerType = item.type;
    self.imageId = item.imageid;
    self.isActive = item.active;
    self.id = item.id;

    self.selectBanner(item.link);
  }
  addNewBanner() {
    this.showLinkDropdown = false;
    this.bannerType = '';
    this.isActive = true;
    this.AddBanner = true;
    this.isEdit = false;
  }

  selectBanner(link: any) {
    this.Link = '';
    if (this.bannerType == 'product') {
      this.showLinkDropdown = true;
      this.getLinkDetails('spo/products-name-list', 'product', link);
    } else if (this.bannerType == 'category') {
      this.showLinkDropdown = true;
      this.getLinkDetails('spo/categories-name-list', 'category', link);
    } else {
      this.showLinkDropdown = false;
    }
  }

  linkData: any = [];

  getLinkDetails(endPoint: any, type, link) {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    axios
      .get(environment.url + endPoint, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (type == 'product') {
          this.linkData = response.data.data.productsNameList;
          for (let i = 0; i < this.linkData.length; i++) {
            if (link == this.linkData[i]._id) {
              this.Link = this.linkData[i]._id;
              console.log(this.Link);
            }
          }
        } else if (type == 'category') {
          this.linkData = response.data.data.categoriesNameList;
          if (this.isEdit == true) {
            for (let i = 0; i < this.linkData.length; i++) {
              if (link == this.linkData[i]) {
                this.Link = this.linkData[i];
              }
            }
          }
        }
        // console.log(this.linkData);
      });
  }
}
