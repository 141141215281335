<nz-card>
    <div class="row m-b-30 justify-content-between">
        <div class="col-xl-3 col-lg-6 col-12 col-md-5 col-sm-5 mb-3">
            <div class="d-md-flex">
                <h2>Combo Offer</h2>
            </div>
        </div>
        <div class="col-xl-4 col-md-5 col-sm-5 col-12 mb-3">
            <div class="row justify-content-between">
                <div class="col-12 text-right">
                    <div class="search-input-section">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                            <input type="text" nz-input placeholder="Search Products" />
                        </nz-input-group>

                        <ng-template #suffixButton>
                            <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-3 col-3 text-right add-button-section">
            <a class="publish-button" (click)="publishAll()" nz-button nzType="" [ngClass]="{'unpublish-button': !PublishStatus}">
                <i *ngIf="PublishStatus" nz-icon>Publish All</i>
                <i *ngIf="!PublishStatus" nz-icon>UnPublish All</i>
                <span *ngIf="PublishStatus">Publish All</span>
                <span *ngIf="!PublishStatus">UnPublish All</span>

            </a>
        </div> -->
        <div *ngIf="checkboxActive" class="col-xl-3 col-lg-6 col-md-6 col-8">
            <!-- <a class="publish-button" nz-button nzType=""><span>Publish Selected</span></a> -->
            <div class="">
                <nz-select nzShowSearch [(ngModel)]="selectedOption" class="w-100" nzPlaceHolder="None">
                    <nz-option nzValue="Publish Selected" nzLabel="Publish Selected"></nz-option>
                    <nz-option nzValue="Unpublish Selected" nzLabel="Unpublish Selected"></nz-option>
                </nz-select>
            </div>
        </div>
        <div *ngIf="checkboxActive" class="col-xl-1 col-lg-6 col-md-6 col-4 checkboxColumn">
            <a class="publish-button" nz-button nzType=""><span>Submit</span></a>
        </div>
    </div>
    <nz-table #productsListTable [nzData]="productsList" [nzShowPagination]="false" [nzScroll]="{x: '2000px' , y: tableWidth }">
        <thead>
            <tr>
                <th nzWidth="50px">
                    <label class="checkbox-label" (ngModelChange)="SelectAll()" [(ngModel)]="Checked" nz-checkbox></label>
                    <!-- <span>Status</span> -->
                </th>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsListTable.data;  let i=index">
                <td>
                    <label class="checkbox-label" (ngModelChange)="checkSelected(item)" nz-checkbox [(ngModel)]="item.isSelected"></label>
                </td>
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-badge *ngIf="item.isPublished==true" nzStatus="success"></nz-badge>
                        <span *ngIf="item.isPublished==false">Draft</span>
                        <span *ngIf="item.isPublished==true">Published</span>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerCode}}
                    </div>
                </td>
                <td>
                    <button (click)="showComboDetails()" type="button" class="view-btn" nz-button nzType="default">View</button>
                </td>
                <td>
                    <!-- <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.productImage"></nz-avatar> -->
                    <img [src]="item.productImage" alt="">
                </td>
                <td>
                    <div class="d-flex align-items-center date-field">
                        {{item.offerStart}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center date-field">
                        {{item.offerEnd}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.totalMrp}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.totalOfferPrice}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerPercentage}}
                    </div>
                </td>
                <td>
                    <button (click)="showAddImage()" type="button" class="add-btn" nz-button nzType="primary">Upload</button>
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        <a nz-button nzType="" class="Publish-button" [ngClass]="{'unpublish-button' : PublishStatus}">
                            <span *ngIf="PublishStatus">UnPublish</span>
                            <span *ngIf="!PublishStatus">Published</span>
                        </a>
                    </div>
                </td> -->
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- MODAL SECTION FOR VIEW -->

<nz-modal [nzStyle]="{top : '100px'}" nzCancelText="Back" [(nzVisible)]="DetailsShow" nzTitle="Combo Offer Item Details" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="offer-itm-details container-fluid" *nzModalContent>
        <app-view-offer-items-page></app-view-offer-items-page>
    </div>
</nz-modal>

<nz-modal [nzStyle]="{top : '20px'}" class="image-add-section" [(nzVisible)]="AddImage" nzTitle="Add Image" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-offer-item></app-add-offer-item> -->
        <app-media-page></app-media-page>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">Back</button>
        <button nz-button nzType="primary" [nzLoading]="isOkLoading">Save</button>
    </div>
</nz-modal>