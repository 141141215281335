import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { NavService } from '../../services/nav.service';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.css'],
})
export class ProductsPageComponent implements OnInit {
  AddImage = false;
  isOkLoading = false;
  MediaPage = false;
  productId = '';
  imageId = '';
  productsList = [];
  searchFilter = '';

  getscreenwidth: any;
  tableWidth = '';

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  pageIndex = 1;
  totalCount: number = 0;
  pageSize: number = 0;

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    var self = this;
    self.spinner.show();
    var type = {
      userType: 'Admin',
    };
    axios
      .get(
        environment.url +
          'products?page=' +
          this.pageIndex +
          '&search=' +
          this.searchFilter,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.totalCount = response?.data?.data?.pagination?.totalCount;
        this.pageSize = response?.data?.data?.pagination?.limit;
        self.productsList = response.data.data.products.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              itemCode:
                data.itemCode < 100000 ? '0' + data.itemCode : data.itemCode,
              itemName: data.itemName,
              image:
                data.isProductHasImage == true
                  ? environment.url + '' + data.image[0].image
                  : undefined, //data.images[0].image.url,
            };
          }
        );
        self.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  orderColumn = [
    {
      width: '50px',
      title: 'S.No',
    },
    {
      width: '150px',
      title: 'Item code',
    },
    {
      width: '180px',
      title: 'Product name',
    },
    {
      width: '150px',
      title: 'Product Image',
    },
    {
      width: '150px',
      title: 'Add Image',
    },
    // {
    // width:'150px',
    //   title: 'Action',
    // },
  ];

  constructor(
    public navService: NavService,
    private spinner: NgxSpinnerService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.fetchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  handleCancel(): void {
    this.AddImage = false;
    this.MediaPage = false;
  }

  addNewImage() {
    this.AddImage = true;
  }

  showMedia(value) {
    this.productId = value.id;
    this.MediaPage = true;
  }
  sendMedia() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    this.navService.formRefreshAnnouncedSource.subscribe((item) => {
      this.imageId = item;
    });
    var imageDetils = {
      productId: this.productId,
      imageId: this.imageId,
    };
    axios
      .post(environment.url + 'products/update-image', imageDetils, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.handleCancel();
        this.fetchData();
        // this.productsList = response.data.data;
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
    this.handleCancel();
  }

  onPageNumberChange(id: any, event: any) {
    if (event.pageIndex != this.pageIndex) {
      this.spinner.show();
      this.pageIndex = event.pageIndex;
      this.fetchData();
      document.getElementById(id).scrollIntoView();
      window.scroll(0, 0);
    }
  }

  searchProducts() {
    this.pageIndex = 1;
    this.fetchData();
  }

  clearCheck() {
    if (!this.searchFilter) {
      this.searchProducts();
    }
  }
}
