<nz-card>
    <!-- <div class="row m-b-30 heading-filter-section">
        <div class="col-lg-10">
            <div class="d-md-flex">
                <div class="m-b-10 m-r-20">
                    <button (click)="getAllData()" nz-button nzType="default">All</button>
                </div>
                <div class="m-b-10 m-r-20">
                    <nz-date-picker [(ngModel)]="fromDate" nzPlaceHolder="From:"></nz-date-picker>
                    <nz-input-group [nzPrefix]="prefixTemplate">
                        <input type="text" nz-input placeholder="Search Project">
                    </nz-input-group>
                    <ng-template #prefixTemplate>
                        <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                </div>
                <div class="m-b-10 m-r-20">
                    <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="To:"></nz-date-picker>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Location">
                        <nz-option *ngFor="let item of locationlist" nzLabel="{{item.locationName}}" nzValue="{{item.locationName}}"></nz-option>
                    </nz-select>
                </div>
                <div class="d-md-flex">
                    <div class="m-b-10 m-r-20">
                        <button (click)="submitFilter()" nz-button nzType="default">Search</button>
                    </div>
                </div>
                <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Status">
                        <nz-option nzLabel="All" nzValue="All"></nz-option>
                        <nz-option nzLabel="In Stock" nzValue="in stock"></nz-option>
                        <nz-option nzLabel="Out Of Stock" nzValue="out of stock"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row justify-content-center">
        <div class="col-xl-10">
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-sm-6 mb-3">
                    <nz-card routerLink="/customerPage" class="card-design1 card-design">
                        <div class="details-section">
                            <div class="user-icon">
                                <img src="../../../assets/AdminPanel/dashboard/user.svg" alt="">
                            </div>
                            <div class="user-details">
                                <h5>Total Members</h5>
                                <h1>{{totalcount}}</h1>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-lg-4 col-sm-6 mb-3">
                    <nz-card routerLink="/customerPage" class="card-design2 card-design">
                        <div class="details-section">
                            <div class="user-icon">
                                <img src="../../../assets/AdminPanel/dashboard/active-user.svg" alt="">
                            </div>
                            <div class="user-details">
                                <h5>Active Members</h5>
                                <h1>{{activeUsers}}</h1>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <!-- <div class="col-lg-4 col-sm-6 mb-3">
                    <nz-card routerLink="/UserReferral" class="card-design3 card-design">
                        <div class="details-section">
                            <div class="user-icon">
                                <img src="../../../assets/AdminPanel/dashboard/total-referral.svg" alt="">
                            </div>
                            <div class="user-details">
                                <h5>Total Referrals</h5>
                                <h1>{{totalRefferal}}</h1>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-lg-4 col-sm-6 mb-3">
                    <nz-card routerLink="/UserReferral" class="card-design4 card-design">
                        <div class="details-section">
                            <div class="user-icon">
                                <img src="../../../assets/AdminPanel/dashboard/customer.svg" alt="">
                            </div>
                            <div class="user-details">
                                <h5>Referral Conversion</h5>
                                <h1>{{turnedToCustomer}}</h1>
                            </div>
                        </div>
                    </nz-card>
                </div> -->
                <div class="col-lg-4 col-sm-6 mb-3">
                    <nz-card routerLink="/single-product-offer" class="card-design5 card-design">
                        <div class="details-section">
                            <div class="user-icon">
                                <img src="../../../assets/AdminPanel/dashboard/offer.svg" alt="">
                            </div>
                            <div class="user-details">
                                <h5>Active Offers</h5>
                                <h1>{{currentRunningOffer}}</h1>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>
        </div>

        <!-- TABLE SECTION STARTS -->
        <div class="col-xl-10 mb-3">
            <div class="d-md-flex">
                <h2>Most Wishlisted Products</h2>
            </div>
        </div>
        <div class="col-xl-10">
            <nz-table #productWishListedTable [nzData]="productWishListed" [nzShowPagination]="false"
                [nzScroll]="{x: '700px' , y: tableWidth }">
                <thead>
                    <tr>
                        <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                            column.title }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of productWishListedTable.data;  let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.itemName}}</td>
                        <td>{{item.offerName}}</td>
                        <td>{{item.totalWishlisted}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
        <!-- TABLE SECTION ENDS -->
    </div>


</nz-card>


<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>