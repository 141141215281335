import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import axios, { Axios } from 'axios';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { PLATFORM_ID } from '@angular/core';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {
  passwordVisible = false;

  loginForm: FormGroup;
  mailId = '';
  password = '';
  logging = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  submitForm(): void {
    for (const i in this.loginForm?.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
  }

  ngOnInit(): void {}

  loggedIn() {
    var MY_DATA_KEY1: StateKey<string> = makeStateKey<string>('Token');
    var MY_DATA_KEY2: StateKey<string> = makeStateKey<string>('userRole');
    var MY_DATA_KEY3: StateKey<string> = makeStateKey<string>('userName');
    if ((<HTMLInputElement>document.getElementById('mailId')).value == '') {
      this.toastr.error('', 'Please Enter Email Id', {
        timeOut: 2500,
      });
    } else if (
      (<HTMLInputElement>document.getElementById('password')).value == ''
    ) {
      this.toastr.error('', 'Please Enter Password', {
        timeOut: 2500,
      });
    } else {
      this.logging = true;
      var loginDetails = {
        email: (<HTMLInputElement>document.getElementById('mailId')).value,
        password: (<HTMLInputElement>document.getElementById('password')).value,
      };
      let self = this;

      axios
        .post(environment.url + 'auth/admin-login', loginDetails)
        .then((response) => {
          if (response.data.status == 'success') {
            console.log(response.data.data);

            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem('Token', response.data.data.token);
              localStorage.setItem('userRole', response.data.data.userRole);
              localStorage.setItem('userName', response.data.data.userName);
            } else {
              this.transferState.set(MY_DATA_KEY1, response.data.data.token);
              this.transferState.set(MY_DATA_KEY2, response.data.data.userRole);
              this.transferState.set(MY_DATA_KEY3, response.data.data.userName);
            }
            this.logging = false;
            if (
              response.data.data.userRole == 'Super Admin' ||
              response.data.data.userRole == 'Sub Admin'
            ) {
              self.router.navigate(['dashboard']);
            } else if (
              response.data.data.userRole == 'Offer Maker' ||
              response.data.data.userRole == 'Offer Admin'
            ) {
              self.router.navigate(['single-product-offer']);
            } else if (response.data.data.userRole == 'CSR') {
              self.router.navigate(['customerPage']);
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.logging = false;
          if (error?.response?.data?.data == undefined) {
            self.toastr.error('', error?.response?.data?.message, {
              timeOut: 2500,
            });
          } else {
            if (
              error.response.data.data[0].msg == 'Invalid value' &&
              error.response.data.data[0].param == 'password'
            ) {
              self.toastr.error('', 'Check Your Password Again', {
                timeOut: 2500,
              });
            } else {
              self.toastr.error('', error?.response?.data?.data?.[0]?.msg, {
                timeOut: 2500,
              });
            }
          }
        });
    }
    //th

    //alert(this.password);
    //this.router.navigate(['dashboard'])
  }
}
