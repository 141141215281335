import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-offer-item',
  templateUrl: './add-offer-item.component.html',
  styleUrls: ['./add-offer-item.component.css']
})
export class AddOfferItemComponent implements OnInit {

  MediaPage = false;
  isOkLoading = false;

  validateForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({});
  }

  resetForm(): void {
    this.validateForm.reset();
  }

  handleCancel(): void {
    this.MediaPage=false;
  }

  showMedia(){
    this.MediaPage=true;
  }

}
