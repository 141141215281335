<nz-card>

    <div class="row m-b-30">
        <div class="col-lg-8 col-md-8 col-sm-5 col-12 mb-3">
            <div class="d-md-flex">
                <h2> User Referrals</h2>
            </div>
        </div>
        <div class="col-lg-4 col-sm-5 col-12 text-right">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <!-- <input type="text" nz-input placeholder="Search Customer" /> -->
                    <input type="text" [(ngModel)]="searchFilter" [ngModelOptions]="{standalone: true}" nz-input placeholder="Search Referrals" />

                </nz-input-group>

                <ng-template #suffixButton>
                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>
    </div>

    <nz-table #ReferralListTable [nzData]="ReferralList" [nzShowPagination]="true" [nzScroll]="{x: '1000px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of ReferralListTable.data | filter:searchFilter;;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.mobile}}
                    </div>
                </td>

                <td>
                    <div class="d-flex align-items-center">
                        {{item.refferedBy}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.status}}
                    </div>
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        {{item.assignedTo}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.response}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center date-field">
                        {{item.dueDate}}
                    </div>
                </td> -->
                <td>
                    <!-- <nz-select nzShowSearch [(ngModel)]="actionStatus" [ngModelOptions]="{standalone: true}" (change)="changeStatus()" class="w-100" nzPlaceHolder="None">
                        <nz-option nzValue="true" nzLabel="Active"></nz-option>
                        <nz-option nzValue="false " nzLabel="Inactive "></nz-option>
                    </nz-select> -->
                    <nz-switch [(ngModel)]="item.memberActiveStatus" (click)="updateStatus(item)"></nz-switch>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>


<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>