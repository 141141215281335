<!-- <div class="container"> -->
<div class="row main-row">
    <div class="col-lg-10">
        <form nz-form>

            <!-- <div class="row"> -->
            <!-- <div class="col-lg-6"> -->
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Item code</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Item code">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <!-- </div> -->

            <!-- <div class="col-lg-6"> -->
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Product Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Product Name">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <!-- </div> -->

            <!-- <div class="col-lg-6"> -->
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandLogo">Image</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <button type="button" nz-button nzType="default" (click)="showMedia()"><span>Upload</span></button>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <!-- </div> -->

            <!-- <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Active</nz-form-label>
                <nz-form-control>
                    <nz-input-group>
                        <nz-switch id="BrandActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item> -->

            <!-- <div class="col-lg-6"> -->
            <!-- <nz-form-item class="text-center">
                <nz-form-control>
                    <button (click)="resetForm()" type="reset" class="mr-5" nz-button nzType="default">Reset</button>
                    <a><button  nz-button nzType="primary">Add</button></a>
                </nz-form-control>
            </nz-form-item> -->
            <!-- </div> -->
            <!-- </div> -->

        </form>

    </div>
</div>
<!-- </div> -->


<!-- MODAL SECTION FOR MEDIA PAGE -->

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="media-section container-fluid" *nzModalContent>
        <app-media-page></app-media-page>
    </div>
</nz-modal>