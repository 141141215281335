<div class="container">
    <div class="row">
        <div class="col-10">
            <div class="text-center mb-5">
                <h2>Add New Attribute</h2>
            </div>
            <form nz-form>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeName">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" nz-input id="attributeName" placeholder="Enter Attribute Name">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeValue">Value</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <input type="text" nz-input id="attributeValue" placeholder="">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeFeature">Feature</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="attributeFeature" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="attributeActive">Active</nz-form-label>
                    <nz-form-control>
                        <nz-input-group>
                            <nz-switch id="attributeActive" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item class="text-center">
                    <nz-form-control>
                        <button class="mr-5" nz-button nzType="default">Reset</button>
                        <a routerLink="/attribute"><button nz-button nzType="primary">Add</button></a>
                    </nz-form-control>
                </nz-form-item>

            </form>

        </div>
    </div>
</div>