import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import axios, { Axios } from 'axios';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css'],
})
export class CustomerDetailsComponent implements OnInit {
  subscription!: Subscription;
  AddUser = false;
  customerId = '';
  religion = 'select';
  maritialStatus = 'select';
  anniversaryDate = '';
  isOkLoading = false;
  CustomerList = [];
  memberId = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  orderColumn = [
    {
      title: 'S.NO',
    },
    {
      title: 'Member ID',
    },
    {
      title: 'Profile Image',
    },
    {
      title: 'Name',
    },
    {
      title: 'Gender',
    },
    {
      title: 'DOB',
    },
    {
      title: 'Mobile Number',
    },
    {
      title: 'Email',
    },
    {
      title: 'Address1',
    },
    {
      title: 'Address2',
    },
    {
      title: 'Area',
    },
    {
      title: 'City',
    },
    {
      title: 'Pincode',
    },
    {
      title: 'Religion',
    },
    {
      title: 'Marital Status',
    },
    {
      title: 'Anniversary Date ',
    },
    {
      title: 'IsActive ',
    },
    {
      title: 'Action ',
    },
  ];

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  fetchData(id) {
    var self = this;

    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    axios
      .get(environment.url + 'auth/qr-user/' + id, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        //this.CustomerList = response.data.data;
        var outputList = [];
        outputList.push(response.data.data.customer);
        this.CustomerList = outputList.map((data: any, index: number) => {
          return {
            id: data._id,
            memberId: data.memberId,
            name: data.name,
            gender: data.gender,
            profileImage: environment.url + '' + data.profileImage, //data.images[0].image.url,
            dateOfBirth: data.dateOfBirth.split('T')[0],
            primaryContactNo: data.primaryContactNo,
            email: data.email,
            addressLine1: data.contactData.addressLine1,
            addressLine2: data.contactData.addressLine2,
            area: data.contactData.area,
            city: data.contactData.city,
            pincode: data.contactData.pincode,
            religion: data.religion,
            martialStatus: data.maritalStatus,
            anniversaryDate: data.anniversaryDate,
            isActive: data.isActive,
          };
        });
        console.log(this.CustomerList[0].profileImage);
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe((data) => {
      console.log(data.id);
      this.memberId = data.id;
    });
    this.fetchData(this.memberId);
  }

  handleCancel() {
    this.AddUser = false;
  }

  editUser(item) {
    this.AddUser = true;
    console.log(item);
    this.customerId = item.id;
  }

  addCustomerDetails() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    // alert(this.religion);
    if (this.religion == 'Select') {
      alert('Select Religion');
    }
    // else if(this.anniversaryDate == ''){
    //   alert('Select Anniversary Date');
    // }
    else if (this.maritialStatus == 'Select') {
      alert('Select Maritial Status');
    } else {
      // alert('validated');
      // alert(this.anniversaryDate);
      // alert(this.customerId);
      var updateDetails = {
        customerId: this.customerId,
        religion: this.religion,
        maritalStatus: this.maritialStatus,
        anniversaryDate: moment(this.anniversaryDate),
      };
      axios
        .post(
          environment.url + 'auth/update-customer-profile-by-admin',
          updateDetails,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.handleCancel();
          this.fetchData(this.memberId);
          // this.productsList = response.data.data;
          //this.attributeList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          //alert(error.response.data.message);
        });
    }
  }
}
