import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-customer-page',
  templateUrl: './add-customer-page.component.html',
  styleUrls: ['./add-customer-page.component.css'],
})
export class AddCustomerPageComponent implements OnInit {
  MediaPage = false;
  isOkLoading = false;

  constructor() {}

  ngOnInit(): void {}

  handleCancel() {
    this.MediaPage = false;
  }

  showMedia(){
    this.MediaPage=true;
  }
}
