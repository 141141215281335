<div class="row main-row">
    <div class="col-lg-12">
        <form nz-form>

            <div class="row main-row">

                <div class="col-lg-6">
                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Member ID</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Member ID">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandLogo">Image</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <button type="button" nz-button nzType="default" (click)="showMedia()"><span>Upload</span></button>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Gender</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Gender">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Email Id</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Email">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Address Line 2</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Address">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">City</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter City">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Religion</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch class="w-100" nzPlaceHolder="None">
                                    <nz-option nzValue="Hindu" nzLabel="Hindu"></nz-option>
                                    <nz-option nzValue="Muslim " nzLabel="Muslim "></nz-option>
                                    <nz-option nzValue="Christian" nzLabel=" Christian"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <!-- <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Anniversary Date</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group> -->
                        <nz-date-picker nzPlaceHolder="Anniversary Date:"></nz-date-picker>
                        <!-- </nz-input-group>
                        </nz-form-control> -->
                    </nz-form-item>

                </div>

                <div class="col-lg-6">

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Name</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Name">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Mobile Number</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Mobile Number">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Date Of Birth</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter date of birth">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Address Line 1</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Address">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Area</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Area">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Pincode</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Pincode">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->



                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Marital Status</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch class="w-100" nzPlaceHolder="None">
                                    <nz-option nzValue="Single" nzLabel="Single"></nz-option>
                                    <nz-option nzValue="Married " nzLabel="Married "></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>


                </div>


            </div>
        </form>

    </div>
</div>

<nz-modal nzCentered [nzStyle]="{top : '30px'}" class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="media-section container-fluid" *nzModalContent>
        <app-media-page></app-media-page>
    </div>
</nz-modal>