<nz-card>

    <div class="row justify-content-between">
        <div class="col-lg-5 col-sm-5 col-12 mb-3">
            <div class="d-md-flex">
                <h2> Store Location Management</h2>
            </div>
        </div>
        <div class="col-lg-4 col-sm-5 col-12 text-right">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" [(ngModel)]="searchFilter" [ngModelOptions]="{standalone: true}" nz-input
                        placeholder="Search Products" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>
        <div class="col-lg-3 col-3 text-right add-button-section">
            <a (click)="addLocation()" nz-button nzType="primary" class="add-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Store Location</span>
            </a>
        </div>
    </div>

    <nz-table #locationlistTable [nzData]="locationlist" [nzShowPagination]="false"
        [nzScroll]="{x: '2500px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of locationlistTable.data |filter: searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.storeName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item?.image"></nz-avatar>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        #{{item.locationCode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.locationName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.address1}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.address2}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.city}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.pincode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center location-url">
                        {{item.url}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.contact}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.email}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.timingOpen}}- {{item.timingClose}}
                    </div>
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        {{item.status}}
                    </div>
                </td> -->

                <td>
                    <div class="store-location-status">
                        <nz-badge [nzStatus]="item.active ? 'success' : 'error'"></nz-badge>
                        <span class="text-capitalize">{{item.active ? 'Active' : 'Inactive'}}</span>
                    </div>
                </td>
                <td>
                    <a (click)="editChanges(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip
                        nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- MODAL SECTION STARTS -->

<nz-modal nzCentered class="image-add-section" [nzStyle]="{top : '50px'}" [nzOkText]="isEdit ? 'Update' : 'Add'"
    nzCancelText="Back" [(nzVisible)]="AddLocation" nzTitle="{{isEdit ? 'Edit' : 'Add New'}} Store Location"
    (nzOnCancel)="handleCancel()" (nzOnOk)="doChanges()" [nzOkLoading]="isOkLoading">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-store-location></app-add-store-location> -->

        <!-- ADD STORELOCATION FORM  -->
        <div class="row main-row">
            <div class="col-lg-12">
                <form nz-form>
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Store Name</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="storeName" [ngModelOptions]="{standalone: true}"
                                    nz-input placeholder="Enter Store Name">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandLogo">Image</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <!-- <button type="button" nz-button nzType="default" (click)="showMedia()"><span>Upload</span></button> -->
                                <!-- <label for='input-file'>Upload</label> -->
                                <input multiple id='input-file' type='file' (change)="uploadImage($event)">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Location Code</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="locationCode" [ngModelOptions]="{standalone: true}"
                                    nz-input placeholder="Enter Location Code*">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Location</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="locationName" [ngModelOptions]="{standalone: true}"
                                    nz-input placeholder="Enter Location*">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Google Map <br> URL</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="googleMapUrl" [ngModelOptions]="{standalone: true}"
                                    nz-input placeholder="Enter URL">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Address 1</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="address1" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Enter Address*">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Address 2</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="address2" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Enter Address*">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>City</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="city" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Enter City*">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Pincode</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="pincode" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Enter Pincode*">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Email</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Email">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Mobile Number</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="number" [(ngModel)]="mobileNo" [ngModelOptions]="{standalone: true}"
                                    nz-input placeholder="Enter Mobile Number">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Opening Time</nz-form-label>
                        <nz-time-picker nzFormat="h:mm a" [nzUse12Hours]="true" [(ngModel)]="openingTime"
                            [ngModelOptions]="{standalone: true}"></nz-time-picker>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Closing Time</nz-form-label>
                        <nz-time-picker nzFormat="h:mm a" [nzUse12Hours]="true" [(ngModel)]="closingTime"
                            [ngModelOptions]="{standalone: true}"></nz-time-picker>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Is Active</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-switch id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"
                                    [nzCheckedChildren]="checkedTemplate"
                                    [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                </form>

            </div>
        </div>

        <!-- MEDIA MODAL -->

        <nz-modal nzCentered class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image"
            (nzOnCancel)="handleCancel()" (nzOnOk)="doChanges()" [nzOkLoading]="isOkLoading">
            <div class="media-section container-fluid" *nzModalContent>
                <app-media-page></app-media-page>
            </div>
        </nz-modal>

    </div>
</nz-modal>


<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>