<div class="container-fluid p-h-0 p-v-20 h-100 bg"
    style="background-image: url('../../../assets/AdminPanel/login/background.png')">
    <div class="d-flex flex-column justify-content-center h-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 col-lg-5 m-h-auto">
                    <div class="main-logo">
                        <img class="img-fluid logo" alt="" src="../../../assets/AdminPanel/Logo/main-logo.png">
                    </div>
                    <nz-card class="m-b-100 shadow-lg">
                        <div class="d-flex align-items-center justify-content-center m-b-30">
                            <h2 class="m-b-0">Sign In</h2>
                        </div>
                        <form nz-form nzLayout="vertical" class="login-form" (ngSubmit)="loggedIn()">
                            <nz-form-item>
                                <nz-form-label nzRequired>Username</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group [nzPrefix]="prefixUser">
                                        <input type="text" id="mailId" [(ngModel)]="mailId"
                                            [ngModelOptions]="{standalone: true}" nz-input placeholder="Username">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item class="relative">
                                <nz-form-label nzRequired>Password</nz-form-label>
                                <a class="float-right font-size-13 forget" style="position:absolute; right: 0"
                                    routerLink="/forgot-password">Forget Password?</a>
                                <nz-form-control nzErrorTip="Please input your Password!">
                                    <!-- <nz-input-group [nzPrefix]="prefixLock">
                                        <input type="password" id="password" [(ngModel)]="password" nz-input placeholder="Password">
                                    </nz-input-group> -->

                                    <nz-input-group [nzPrefix]="prefixLock">
                                        <input type="password" id="password" nz-input placeholder="input password"
                                            [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
                                    </nz-input-group>

                                </nz-form-control>
                            </nz-form-item>


                            <nz-form-item>
                                <nz-form-control>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <!-- <span class="font-size-13 text-muted">Don't have an account? <a class="small" [routerLink]="['/authentication/sign-up-1']"> Signup</a></span> -->
                                        <button [nzLoading]="logging" type="submit" nz-button
                                            class="login-form-button">Sign In</button>
                                    </div>
                                </nz-form-control>
                            </nz-form-item>
                        </form>
                        <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
                        <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
                    </nz-card>
                </div>
            </div>
        </div>
        <!-- <div class="d-none d-md-flex p-h-40 justify-content-between">
            <span class="">© 2019 ThemeNate</span>
            <ul class="list-inline">
                <li class="list-inline-item">
                    <a class="text-dark text-link" href="">Legal</a>
                </li>
                <li class="list-inline-item">
                    <a class="text-dark text-link" href="">Privacy</a>
                </li>
            </ul>
        </div> -->
    </div>
</div>


<!-- PASSWORD SHOW HIDE -->

<ng-template #suffixTemplate>
    <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
</ng-template>

<!-- [formGroup]="loginForm"  goes to line 12 -->
<!-- (ngSubmit)="submitForm()"  goes to line 12-->
<!-- formControlName="password" formControlName="name" goes to line 26 and goes to line 16 -->
<!-- [type]="passwordVisible ? 'text' : 'password'" [nzSuffix]="suffixTemplate"  HIDE PASSWORD SECTION -->