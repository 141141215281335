<div class="container">
    <div class="row justify-content-start">
        <div class="col-12 d-flex justify-content-between mb-5">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" [(ngModel)]="searchFilter" (keyup)="clearCheck()" (keyup.enter)="searchMedia()"
                        nz-input placeholder="Search Images" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i (click)="searchMedia()" nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
            <!-- <button (click)="sortBy()">Sort</button> -->
            <button (click)="sortBy()" nz-button nzType="primary">Sort by name</button>

            <!-- <div class="">
                <a nz-button nzType="primary">
                    <span>Refresh</span>
                </a>
            </div> -->
            <!-- <div class="upload-button ">
                <label for='input-file'>Upload</label>
                <input id='input-file' type='file' multiple (change)="uploadImage($event)">
            </div> -->
        </div>


        <!-- <div *ngFor="let image of gallery; let i=index" class="col-lg-2">
            <nz-card [ngClass]="{'image-card-section' : setBorder}" class="text-center" #local>
                <img (click)="selectedImage(i)" class="img-fluid" [src]="image.src" alt="">
            </nz-card>
        </div> -->

        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10" [scrollWindow]="false"
            (scrolled)="onScroll()" class="row scroll_section" [ngClass]="{'media_page' : mediaPage}"
            style="width:100%;">
            <div class="col-lg-2 col-sm-3 col-6 main-card mb-5 upload-section">
                <div class="upload-body" [ngClass]="{'upload_body_other_page' : !mediaPage}">
                    <div class="">
                        <label for='input-file'><img class="img-fluid product-image"
                                [ngClass]="{'product_image_other_page' : !mediaPage}"
                                src="../../../assets/images/Product-upload.png" /></label>
                        <!-- <img class="img-fluid product-image" src="../../../assets/images/Product-upload.png" /> -->
                    </div>
                    <div class="upload-footer">
                        <nz-input-group class="upload-button-start">
                            <!-- <p>+ Upload</p> -->
                            <label for='input-file'>Browse</label>
                            <input id='input-file' type='file' multiple (change)="uploadImage($event)">
                        </nz-input-group>
                    </div>
                </div>
            </div>

            <div *ngFor="let image of gallery ; let i=index" class="col-lg-2 col-sm-3 col-6 main-card mb-5">
                <card class="text-center card">
                    <input #check type="checkbox" (click)="checkChange(check)" id="{{image.id}}">
                    <label for="{{image.id}}">
                        <!-- <div class=""> -->
                        <img class="img-fluid product-image" [ngClass]="{'product_image_other_page' : !mediaPage}"
                            [src]="image.src" />
                        <!-- </div> -->
                        <div class="product-title-section">
                            <nz-input-group>
                                <input type="text" [readonly]=true nz-input value={{image.name}}>
                            </nz-input-group>
                        </div>
                        <!-- <p contentEditable="false">Lorem Ipsum</p> --name  image.editText
                                <a *ngIf="image.editText" (click)="textEditable(i , 'edit')" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Edit">
                                    <i nz-icon nzType="edit" theme="outline"></i>
                                </a>
                                <a *ngIf="!image.editText" (click)="textEditable(i , 'save')"  class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip nzTooltipTitle="Save">
                                    <i nz-icon nzType="check" nzTheme="outline"></i>
                                </a> -->
                    </label>
                </card>
            </div>
        </div>
    </div>
</div>


<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>

<!-- <div class="cont-bg"> -->

<!-- <div class="cont-main"> -->
<!-- <div class="cont-checkbox" *ngFor="let image of gallery; let i=index">
    <input type="checkbox" id="{{image.id}}" />
    <label for="{{image.id}}">
          <img [src]="image.src" />
           <span class="cover-checkbox">
             <svg viewBox="0 0 12 10">
               <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
             </svg>
           </span>
       </label>
</div> -->
<!-- </div> -->
<!-- </div> -->