import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import axios, { Axios } from 'axios';
import { NavService } from '../../services/nav.service';
import * as _ from 'underscore';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.css'],
})
export class MediaPageComponent implements OnInit {
  searchInput: string;
  setBorder = false;
  imageurls = [];
  searchFilter = '';

  mediaPage = false;
  gallery = [];

  MaxSelect = [];

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  // scroll pagination

  initialPage = true;
  pageIndex = 1;

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    this.spinner.show();
    axios
      .get(
        environment.url +
          'media?page=' +
          this.pageIndex +
          '&search=' +
          this.searchFilter,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        var temp = [];
        temp = response.data.data.media.map((data: any, index: number) => {
          return {
            id: data._id,
            name: data.name,
            src: environment.url + '' + data.image, //data.images[0].image.url,
          };
        });

        if (this.initialPage) {
          this.gallery = temp;
        } else if (!this.initialPage) {
          this.gallery = this.gallery.concat(temp);
        }
        //this.attributeList = response.data.data;
        console.log(this.gallery);
        this.spinner.hide();
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  constructor(
    private router: Router,
    public navService: NavService,
    private spinner: NgxSpinnerService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // selectedImage(a: any) {
  //   this.imageurls.splice(a, 1);
  //   console.log(a);
  // }

  checkChange(event) {
    let currentUrl = this.router.url;
    if (currentUrl == '/media') {
      event.checked = false;
      // return null
    }
    // console.log(this.MaxSelect.length);
    if (this.MaxSelect.length >= 1 && this.MaxSelect.indexOf(event.id) == -1) {
      alert('You Can Only Select 1 Images Maximum');
      event.checked = false;
    }
    if (this.MaxSelect.indexOf(event.id) == -1) {
      if (this.MaxSelect.length < 1) {
        this.MaxSelect.push(event.id);
      }
    } else {
      this.MaxSelect.splice(this.MaxSelect.indexOf(event.id), 1);
    }
    this.navService.publishFormRefresh(this.MaxSelect);
    // }

    // console.log('after', this.MaxSelect.length);
    console.log(this.MaxSelect);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    } else {
      this.document.body.scrollTop = 0; // For Safari
      this.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    }
    this.initialPage = true;
    this.pageIndex = 1;
    this.fetchData();
    if (this.router.url == '/media') {
      this.mediaPage = true;
    } else {
      this.mediaPage = false;
    }
  }

  textEditable(j: any, action: any) {
    if (action == 'edit') {
      this.gallery[j].editText = !this.gallery[j].editText;
    }
    if (action == 'save') {
      this.gallery[j].editText = !this.gallery[j].editText;
    }
  }

  uploadImage(event: any) {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var formData = new FormData();
    console.log(event.target.files);
    for (var i = 0; i < event.target.files.length; i++) {
      formData.append('images', event.target.files[i]);
    }

    axios
      .post(environment.url + 'media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.data.message == 'media uploaded') {
          this.initialPage = true;
          this.pageIndex = 1;
          this.fetchData();
        }
        //this.gallery = response.data.data;
        // this.gallery = initialResult.map((data: any, index: number) => {
        //   return {
        //     name : data.name,
        //     logo : data.image.image.url,
        //     website : data.website

        //   }});
        //   console.log(this.productsList[0].logo);

        //console.log(response.data.status);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  }
  sortBy() {
    this.gallery = _.sortBy(this.gallery, 'name');
  }

  // pagination section

  onScroll() {
    // alert('page end');
    this.initialPage = false;
    this.pageIndex = this.pageIndex + 1;
    this.fetchData();
  }

  searchMedia() {
    this.initialPage = true;
    this.pageIndex = 1;
    this.fetchData();
  }

  clearCheck() {
    if (!this.searchFilter) {
      this.searchMedia();
    }
  }
}
