import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeConstantService } from '../../services/theme-constant.service';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  userName: any = '';
  userRole: any = '';
  location = '';

  constructor(
    private themeService: ThemeConstantService,
    private router: Router,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  LocationList = [
    {
      name: 'chennai',
    },
    {
      name: 'Trichy',
    },
    {
      name: 'Kumbakkonam',
    },
    {
      name: 'Nagapattinam',
    },
  ];

  MY_DATA_KEY1: StateKey<string> = makeStateKey<string>('userName');
  MY_DATA_KEY2: StateKey<string> = makeStateKey<string>('userRole');

  ngOnInit(): void {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      this.userName = localStorage.getItem('userName');
      this.userRole = localStorage.getItem('userRole');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY1)) {
      this.userName = this.transferState.get(this.MY_DATA_KEY1, null);
      this.userRole = this.transferState.get(this.MY_DATA_KEY2, null);
    }
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  notificationList = [
    {
      title: 'You received a new message',
      time: '8 min',
      icon: 'mail',
      color: 'ant-avatar-' + 'blue',
    },
    {
      title: 'New user registered',
      time: '7 hours',
      icon: 'user-add',
      color: 'ant-avatar-' + 'cyan',
    },
    {
      title: 'System Alert',
      time: '8 hours',
      icon: 'warning',
      color: 'ant-avatar-' + 'red',
    },
    {
      title: 'You have a new update',
      time: '2 days',
      icon: 'sync',
      color: 'ant-avatar-' + 'gold',
    },
  ];

  Logout() {
    var MY_DATA_KEY1: StateKey<string> = makeStateKey<string>('Token');
    var MY_DATA_KEY2: StateKey<string> = makeStateKey<string>('userName');
    var MY_DATA_KEY3: StateKey<string> = makeStateKey<string>('userRole');

    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('Token');
      localStorage.removeItem('userName');
      localStorage.removeItem('userRole');
    } else {
      this.transferState.remove(MY_DATA_KEY1);
      this.transferState.remove(MY_DATA_KEY2);
      this.transferState.remove(MY_DATA_KEY3);
    }

    this.router.navigate(['/']);
  }
}
