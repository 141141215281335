<nz-card>
    <div class="row justify-content-between">
        <div class="col-lg-4 col-sm-5 col-12 mb-3">
            <div class="d-md-flex">
                <h2>Banner List</h2>
            </div>
        </div>
        <div class="col-lg-4 col-sm-5 col-12 text-right">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" [(ngModel)]="searchFilter" [ngModelOptions]="{standalone: true}" nz-input
                        placeholder="Search Banner" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>
        <div class="col-lg-3 col-3 text-right add-button-section">
            <a (click)="addNewBanner()" nz-button nzType="primary" class="add-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add Banner</span>
            </a>
        </div>
    </div>

    <nz-table #BannerListTable [nzData]="BannerList" [nzShowPagination]="false"
        [nzScroll]="{x: '900px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of BannerList | filter:searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center" [ngSwitch]="item.type">
                        <span *ngSwitchCase="'spo'">Single product offer</span>
                        <span *ngSwitchCase="'ofy'">Offer only for you</span>
                        <span *ngSwitchCase="'ad'">Advertisement</span>
                        <span *ngSwitchCase="'category'">Category</span>
                        <span *ngSwitchCase="'product'">Product</span>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.active}}
                    </div>
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        {{item.location}}
                    </div>
                </td> -->

                <td>
                    <a (click)="EditUser(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip
                        nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- MODAL SECTION STARTS -->

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="AddBanner" nzTitle="{{isEdit ? 'Edit' : 'Add'}} Image"
    (nzOnCancel)="handleClose()">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-offer-item></app-add-offer-item> -->

        <!-- ADD PRODUCTS FORM -->

        <!-- <div class="container"> -->
        <div class="row main-row">
            <div class="col-lg-12">
                <form nz-form>

                    <!-- <div class="row"> -->
                    <!-- <div class="col-lg-6"> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="6" nzRequired>Banner Type</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch [(ngModel)]="bannerType" [ngModelOptions]="{standalone: true}"
                                    (ngModelChange)="selectBanner()" class="w-100" nzPlaceHolder="None">
                                    <!-- <nz-option nzValue="Select" nzLabel="Select"></nz-option> -->
                                    <nz-option nzValue="spo" nzLabel="Single Product Offer"></nz-option>
                                    <nz-option nzValue="ofy" nzLabel="Only For You"></nz-option>
                                    <nz-option nzValue="ad" nzLabel="Only For Advt"></nz-option>
                                    <nz-option nzValue="product" nzLabel="Product"></nz-option>
                                    <nz-option nzValue="category" nzLabel="Category"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="showLinkDropdown">
                        <nz-form-label [nzSm]="4" [nzMd]="6" nzRequired>Link</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch [(ngModel)]="Link" [ngModelOptions]="{standalone: true}"
                                    class="w-100" nzPlaceHolder="None">
                                    <nz-option *ngFor="let link of linkData; let i = index"
                                        nzValue="{{bannerType == 'product' ? link._id : link}}"
                                        nzLabel="{{bannerType == 'product' ? link.itemName : link}}"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- </div> -->

                    <!-- <div class="col-lg-6"> -->
                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="6" nzRequired nzFor="BrandName">Location</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="location" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Location">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->
                    <!-- </div> -->

                    <!-- <div class="col-lg-6"> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="6" nzRequired nzFor="BrandLogo">Image</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <button type="button" nz-button nzType="default"
                                    (click)="showMedia()"><span>Upload</span></button>
                                <label style="margin-left: 10px;">Image Size : {{245*3}}x{{300*3}}</label>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- </div> -->

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="6" nzRequired nzFor="BrandActive">Active</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-switch id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"
                                    [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate">
                                </nz-switch>
                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                </form>

            </div>
        </div>
        <!-- </div> -->




    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleClose()">Back</button>
        <button nz-button nzType="primary" (click)="doChanges()" [nzLoading]="isOkLoading">
            {{isEdit ? 'Update' : 'Add'}}
        </button>
    </div>
</nz-modal>

<!-- MODAL SECTION FOR MEDIA PAGE -->

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image"
    (nzOnCancel)="handleCancel()">
    <div class="media-section container-fluid" *nzModalContent>
        <app-media-page></app-media-page>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">Back</button>
        <button nz-button nzType="primary" (click)="sendMedia()" [nzLoading]="isOkLoading">Save</button>
    </div>
</nz-modal>

<!-- (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" [nzOkLoading]="isOkLoading" -->
<!-- (nzOnCancel)="handleClose()" (nzOnOk)="doChanges()" [nzOkLoading]="isOkLoading" -->

<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>