<div class="row main-row">
    <div class="col-lg-12">
        <form nz-form>


            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Store Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Store Name">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandLogo">Image</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <button type="button" nz-button nzType="default"
                            (click)="showMedia()"><span>Upload</span></button>
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Location Code</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Location Code*">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Location*</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Location*">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Google Map <br> URL</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter URL">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Address</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Enter Address*">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Email</nz-form-label>
                <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group>
                        <input type="text" nz-input placeholder="Email">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Opening Time</nz-form-label>
                <nz-time-picker [(ngModel)]="OpeningTime"></nz-time-picker>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Closing Time</nz-form-label>
                <nz-time-picker [(ngModel)]="closingTime"></nz-time-picker>
            </nz-form-item>

        </form>

    </div>
</div>

<!-- MEDIA MODAL -->

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image"
    (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="media-section container-fluid" *nzModalContent>
        <app-media-page></app-media-page>
    </div>
</nz-modal>