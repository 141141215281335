import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-user-referral',
  templateUrl: './user-referral.component.html',
  styleUrls: ['./user-referral.component.css'],
})
export class UserReferralComponent implements OnInit {
  ReferralList: any = [];
  actionStatus: any;
  searchFilter = '';

  getscreenwidth: any;
  tableWidth = '';

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    self.spinner.show();
    axios
      .get(environment.url + 'referral/', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.ReferralList = response.data.data.referrals.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              name: data?.name,
              mobile: data.mobileNumber,
              status: data.memberActiveStatus == true ? 'active' : 'in active',
              refferedBy: data.referredBy.name,
              memberActiveStatus: data.memberActiveStatus,
            };
          }
        );
        self.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  orderColumn = [
    {
      width: '50px',
      title: 'S.No',
    },
    {
      width: '100px',
      title: 'Name',
    },
    {
      width: '100px',
      title: 'Mobile Number',
    },

    {
      width: '100px',
      title: 'Referred by',
    },
    {
      width: '100px',
      title: 'Member Status',
    },
    {
      width: '100px',
      title: 'Active',
    },

    // {
    //   title: 'Member Status',
    // },
  ];

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.fetchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }
  updateStatus(item) {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    console.log(item);
    var reqObj = {
      referralId: item.id,
      memberActiveStatus: item.memberActiveStatus,
    };
    axios
      .post(environment.url + 'referral/update/', reqObj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.fetchData();

        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }

  switchValue = true;
}
