import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from './KurinjiAdmin/login-page/login-page.component';
import { ForgotPasswordPageComponent } from './KurinjiAdmin/forgot-password-page/forgot-password-page.component';
import { DashboardPageComponent } from './KurinjiAdmin/dashboard-page/dashboard-page.component';
import { HomrpageComponent } from './KurinjiAdmin/homrpage/homrpage.component';
import { MediaPageComponent } from './KurinjiAdmin/media-page/media-page.component';
import { OfferPageComponent } from './KurinjiAdmin/offer-page/offer-page.component';
import { SingleProductOfferPageComponent } from './KurinjiAdmin/single-product-offer-page/single-product-offer-page.component';
import { ProductsPageComponent } from './KurinjiAdmin/products-page/products-page.component';
import { UserPageComponent } from './KurinjiAdmin/user-page/user-page.component';
import { UserReferralComponent } from './KurinjiAdmin/user-referral/user-referral.component';
import { StoreLocationPageComponent } from './KurinjiAdmin/store-location-page/store-location-page.component';
import { FeedbackPageComponent } from './KurinjiAdmin/feedback-page/feedback-page.component';
import { CustomerPageComponent } from './KurinjiAdmin/customer-page/customer-page.component';
import { SettingPageComponent } from './KurinjiAdmin/setting-page/setting-page.component';
import { BannerPageComponent } from './KurinjiAdmin/banner-page/banner-page.component';
import { AuthGuardService } from './services/auth-guard.service';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';

const appRoutes: Routes = [
  { path: '', component: LoginPageComponent },
  {
    path: 'forgot-password',
    component: ForgotPasswordPageComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'home',
    component: HomrpageComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'dashboard',
    component: DashboardPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'media',
    component: MediaPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'combo-offer',
    component: OfferPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'single-product-offer',
    component: SingleProductOfferPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'products',
    component: ProductsPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'UserPage',
    component: UserPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'UserReferral',
    component: UserReferralComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'StoreLocation',
    component: StoreLocationPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'SuggestionFeedback',
    component: FeedbackPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customerPage',
    component: CustomerPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'SettingPage',
    component: SettingPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'BannerPage',
    component: BannerPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'auth/qr-user/:id',
    component: CustomerDetailsComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes
//   {
//   preloadingStrategy: PreloadAllModules,
//   anchorScrolling: 'enabled',
//   scrollPositionRestoration: 'enabled',
// }
, {
    initialNavigation: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
