<nz-card>
    <div class="row  justify-content-between">
        <div class="col-lg-8 col-sm-5 col-12 mb-3">
            <div class="d-md-flex">
                <h2>Member</h2>
            </div>
        </div>
        <div class="col-lg-4 col-sm-5 col-12 text-right">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" [(ngModel)]="searchFilter" [ngModelOptions]="{standalone: true}" nz-input
                        placeholder="Search Member" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>
    </div>

    <nz-table #CustomerListTable [nzData]="CustomerList" [nzShowPagination]="true"
        [nzScroll]="{x: '3000px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of CustomerList |filter: searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.memberId}}
                    </div>
                </td>
                <!-- <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.profileImage"></nz-avatar>
                     <img [src]="item.profileImage" alt=""> -->
                <!-- </td> -->
                <td>
                    <div class="d-flex align-items-center">
                        {{item.name}}
                    </div>
                </td>

                <td>
                    <div class="d-flex align-items-center">
                        {{item.gender}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{moment(item.dateOfBirth).format('DD-MM-YYYY')}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.primaryContactNo}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.email}}
                    </div>
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        {{item.location}}
                    </div>
                </td> -->
                <td>
                    <div class="d-flex align-items-center">
                        {{item.addressLine1}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.addressLine2}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.area}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.city}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.pincode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.religion}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.martialStatus}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{moment(item.anniversaryDate).format('DD-MM-YYYY')}}
                    </div>
                </td>
                <td>
                    <nz-badge *ngIf="item.isActive == true" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.isActive == false" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.isActive}}</span>
                </td>
                <td>
                    <a (click)="editUser(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip
                        nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="AddUser" nzTitle="Update a Member"
    (nzOnCancel)="handleCancel()">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-customer-page></app-add-customer-page> -->
        <div class="row main-row">
            <div class="col-lg-10">
                <form nz-form>
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Religion</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch class="w-100" [(ngModel)]="religion"
                                    [ngModelOptions]="{standalone: true}" nzPlaceHolder="None">
                                    <nz-option nzValue="Select" nzLabel="Select"></nz-option>
                                    <nz-option nzValue="Hindu" nzLabel="Hindu"></nz-option>
                                    <nz-option nzValue="Muslim " nzLabel="Muslim "></nz-option>
                                    <nz-option nzValue="Christian" nzLabel=" Christian"></nz-option>
                                    <nz-option nzValue="Others" nzLabel=" Others"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" [(ngModel)]="anniversaryDate"
                            [ngModelOptions]="{standalone: true}" nzRequired nzFor="BrandName">Anniversary
                            Date</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <nz-date-picker [(ngModel)]="anniversaryDate" [ngModelOptions]="{standalone: true}"
                                    nzPlaceHolder="Anniversary Date:"></nz-date-picker>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <!-- </div> -->

                    <!-- <div class="col-lg-6"> -->

                    <!-- <nz-form-item>
                                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Name</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group>
                                        <input type="text" nz-input placeholder="Enter Name">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item> -->

                    <!-- <nz-form-item>
                                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Mobile Number</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group>
                                        <input type="text" nz-input placeholder="Enter Mobile Number">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item> -->

                    <!-- <nz-form-item>
                                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Date Of Birth</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group>
                                        <input type="text" nz-input placeholder="Enter date of birth">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
        
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Address Line 1</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group>
                                        <input type="text" nz-input placeholder="Enter Address">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
        
        
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Area</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group>
                                        <input type="text" nz-input placeholder="Enter Area">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item>
        
                            <nz-form-item>
                                <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Pincode</nz-form-label>
                                <nz-form-control nzErrorTip="Please input your username!">
                                    <nz-input-group>
                                        <input type="text" nz-input placeholder="Enter Pincode">
                                    </nz-input-group>
                                </nz-form-control>
                            </nz-form-item> -->



                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Marital Status</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch class="w-100" [(ngModel)]="maritialStatus"
                                    [ngModelOptions]="{standalone: true}" nzPlaceHolder="None">
                                    <nz-option nzValue="Select" nzLabel="Select"></nz-option>
                                    <nz-option nzValue="Unmarried" nzLabel="Single"></nz-option>
                                    <nz-option nzValue="Married" nzLabel="Married "></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <!-- <nz-form-control>
                        
                        <nz-input-group>
                            <nz-switch id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                        </nz-input-group>
                    </nz-form-control> -->
                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Suspend The
                            Member</nz-form-label>
                        <p (click)="showAlert()">{{(isActive)?"Suspend":"Unsuspend"}}</p>
                        <!-- <nz-form-control>
                            <nz-input-group>
                                <nz-switch (click)="showAlert()" id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                            </nz-input-group>
                        </nz-form-control> -->
                    </nz-form-item>



                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Suspend The Customer</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <button (click)="changeSuspendStatus()" [ngModelOptions]="{standalone: true}" [ngClass]="{'SuspendedButton' : suspendStatus}" nz-button nzType="default"><span *ngIf="!suspendStatus">Suspend</span><span *ngIf="suspendStatus">Suspended</span></button>
                               <nz-switch id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                        <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                        <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template> -->
                    <!-- </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->


                    <!-- </div> -->


                    <!-- </div> -->
                </form>

            </div>
        </div>

        <nz-modal nzWidth="350px !important" nzCentered class="alert-modal" nzOkText="Yes" nzCancelText="No"
            [(nzVisible)]="AlertMessage" nzTitle="Alert" (nzOnCancel)="alertCancel()" (nzOnOk)="alertSuccess()"
            [nzOkLoading]="isOkLoading">
            <div class="text-left" *nzModalContent>
                <h4>Are You Sure want to Suspend the Member</h4>
            </div>
        </nz-modal>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">Back</button>
        <button nz-button nzType="primary" (click)="addCustomerDetails()" [nzLoading]="isOkLoading">Save</button>
    </div>
</nz-modal>

<!-- (nzOnCancel)="handleCancel()" (nzOnOk)="addCustomerDetails()" [nzOkLoading]="isOkLoading" -->

<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>