import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import { fil } from 'date-fns/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css'],
})
export class DashboardPageComponent implements OnInit {
  fromDate = null;
  toDate = null;
  totalcount = '';
  activeUsers = '';
  totalRefferal = '';
  turnedToCustomer = '';
  currentRunningOffer = '';
  locationlist = [];
  productWishListed = [];
  tableWidth = '';

  constructor(
    private spinner: NgxSpinnerService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  orderColumn = [
    {
      width: '50px',
      title: 'S.No',
    },
    {
      width: '150px',
      title: 'Item Name',
    },
    {
      width: '100px',
      title: 'Offer Name',
    },
    {
      width: '50px',
      title: 'Count',
    },
  ];

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  fetchData(value: any) {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    self.spinner.show();
    // if(value == 'all'){
    //   var reqObj = {
    //     "fetchType": "all",
    //   }
    // }
    axios
      .post(
        environment.url + 'custom/dashboard/',
        // axios.post('http://192.168.1.14:5700/custom/dashboard/',
        value,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        self.productWishListed = response.data.data.productsWithWishlisted;
        self.totalcount = response.data.data.customerCount;
        self.activeUsers = response.data.data.activeUsers;
        self.totalRefferal = response.data.data.referralCount;
        self.turnedToCustomer =
          response.data.data.referralTurnedToCustomerCount;
        self.currentRunningOffer = response.data.data.offersCount;
        self.fetchStoreLocations();

        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  fetchStoreLocations() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    var self = this;

    axios
      .get(
        environment.url + 'store-locations/',

        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        this.locationlist = response.data.data.storeLocations;
        self.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth > 1024) {
        this.tableWidth = '350px';
      } else {
        this.tableWidth = '300px';
      }
    }
    var reqObj = {
      fetchType: 'all',
    };
    this.fetchData(reqObj);
  }
  submitFilter() {
    var d1 = new Date(
      new Date(this.fromDate).getFullYear(),
      new Date(this.fromDate).getMonth(),
      new Date(this.fromDate).getDate()
    );
    var d2 = new Date(
      new Date(this.toDate).getFullYear(),
      new Date(this.toDate).getMonth(),
      new Date(this.toDate).getDate()
    );
    if (d2 < d1) {
      alert('From Date is Always Lesser than To Date');
      return false;
    }
    var filter = {
      fetchType: 'by-date',
      fromDate:
        new Date(this.fromDate).getFullYear() +
        '-' +
        (new Date(this.fromDate).getMonth() + 1 > 9)
          ? new Date(this.fromDate).getMonth() + 1
          : 0 +
            (new Date(this.fromDate).getMonth() + 1) +
            '-' +
            new Date(this.fromDate).getDate() +
            'T00:00:00+05:30',
      toDate:
        new Date(this.toDate).getFullYear() +
        '-' +
        (new Date(this.toDate).getMonth() + 1 > 9)
          ? new Date(this.toDate).getMonth() + 1
          : 0 +
            (new Date(this.toDate).getMonth() + 1) +
            '-' +
            new Date(this.toDate).getDate() +
            'T00:00:00+05:30',
    };
    this.fetchData(filter);
  }
  getAllData() {
    var reqObj = {
      fetchType: 'all',
    };
    this.fetchData(reqObj);
  }
}
