import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    acceptedUser : [
      'Super Admin',
      'Sub Admin'
    ],
    submenu: [],
  },
  {
    path: '/customerPage',
    title: 'Member List',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user',
    acceptedUser : [
      'Super Admin',
    ],
    submenu: [],
  },
  {
    path: '/media',
    title: 'Media Storage',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'file-image',
    acceptedUser : [
      'Super Admin',
      'Offer Admin',
      'Offer Maker'
    ],
    submenu: [],
  },
  {
    path: '/BannerPage',
    title: 'Banner',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'picture',
    acceptedUser : [
      'Super Admin',
      'Sub Admin'
    ],
    submenu: [],
  },
  {
    path: '',
    title: 'Offer',
    iconType: 'nzIcon',
    iconTheme: 'fill',
    icon: 'gift',
    acceptedUser : [
      'Super Admin',
      'Sub Admin'
    ],
    submenu: [
      {
        path: '/single-product-offer',
        title: 'Current Offers',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'gift',
        acceptedUser : [
          'Super Admin',
          'Sub Admin'
        ],
        submenu: [],
      },
      // {
      //   path: '/combo-offer',
      //   title: 'Combo Offer',
      //   iconType: 'nzIcon',
      //   iconTheme: 'outline',
      //   icon: 'shopping-cart',
      //   acceptedUser : [
      //     'Super Admin',
      //     'Sub Admin'
      //   ],
      //   submenu: [],
      // },
      
    ],
  },
  {
    path: '/products',
    title: 'Products',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'tags',
    acceptedUser : [
      'Super Admin',
      'Offer Admin',
      'Offer Maker'
    ],
    submenu: [],
  },
  {
    path: '/UserPage',
    title: 'User',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'user-add',
    acceptedUser : [
      'Super Admin',
      'Sub Admin'
    ],
    submenu: [],
  },
  // {
  //   path: '/UserReferral',
  //   title: 'User Referral',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'usergroup-add',
  //   acceptedUser : [
  //     'Super Admin',
  //     'Sub Admin',
  //     'CSR'
  //   ],
  //   submenu: [],
  // },
  {
    path: '/StoreLocation',
    title: 'Store Location',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'environment',
    acceptedUser : [
      'Super Admin',
      'Sub Admin',
      'CSR'
    ],
    submenu: [],
  },

  {
    path: '/SuggestionFeedback',
    title: 'Suggestion & Feedback',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'message',
    acceptedUser : [
      'Super Admin',
      'Sub Admin'
    ],
    submenu: [],
  },
  {
    path: '/SettingPage',
    title: 'Settings',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'setting',
    acceptedUser : [
      'Super Admin',
      'Sub Admin'
    ],
    submenu: [],
  },
  
];
