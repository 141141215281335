import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.css'],
})
export class SettingPageComponent implements OnInit {
  settingList = [];
  ecommerceLink = '';
  aboutUsLink = '';
  privacyPolicyLink = '';
  appLink = '';
  rateLink = '';
  faqLink = '';

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    this.spinner.show();
    axios
      .get(environment.url + 'settings/', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.settingList = response.data.data.settings;
        this.ecommerceLink = this.settingList[0].ecommerceLink;
        this.aboutUsLink = this.settingList[0].aboutUsLink;
        this.privacyPolicyLink = this.settingList[0].privacyPolicy;
        this.appLink = this.settingList[0].shareAppLink;
        this.rateLink = this.settingList[0].rateUsLink;
        this.faqLink = this.settingList[0].faqLink;
        this.spinner.hide();
        //this.attributeList = response.data.data;privacyPolicy
        //console.log(this.productsList);
        //this.fetchPublishStatus();
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  ngOnInit(): void {
    this.fetchData();
  }
  updateSettings() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }
    var request = {
      ecommerceLink: this.ecommerceLink,
      aboutUsLink: this.aboutUsLink,
      privacyPolicy: this.privacyPolicyLink,
      rateUsLink: this.rateLink,
      faqLink: this.faqLink,
      shareAppLink: this.appLink,
      ofy: false,
      cpo: false,
      spo: true,
    };
    axios
      .post(environment.url + 'settings/update', request, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        // console.log(response);
        this.toastr.success('', response.data.message, {
          timeOut: 2500,
        });
        this.fetchData();
        // this.productsList = response.data.data;
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error.response);
        this.toastr.error('', error.response.data.message, {
          timeOut: 2500,
        });
        //alert(error.response.data.message);
      });
  }
}
