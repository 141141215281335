<nz-card>
    <div class="row ">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <h2>Suggestion & Feedback</h2>
            </div>
        </div>
    </div>

    <nz-table #FeedbackListTable [nzData]="FeedbackList" [nzShowPagination]="false"
        [nzScroll]="{x: '900px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of FeedbackListTable.data;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.type}}
                    </div>
                </td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                    <!-- <img [src]="item.image" alt=""> -->
                </td>
                <td>
                    <div class="d-flex align-items-center description">
                        {{item.description}}
                    </div>
                </td>

                <td>
                    <div class="d-flex align-items-center">
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{moment(item.date).format('DD-MM-YYYY hh:mm')}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.mobileNumber}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.remarks}}
                    </div>
                </td>
                <td>
                    <!-- <nz-switch [(ngModel)]="item.responded" (click)="RespondedStatus(item)"></nz-switch> -->
                    <nz-badge *ngIf="item.responded == true" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.responded == false" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.responded}}</span>
                </td>
                <td>
                    <!-- <nz-switch [(ngModel)]="item.addressed" (click)="AddressedStatus(item)"></nz-switch> -->
                    <nz-badge *ngIf="item.addressed == true" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.addressed == false" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.addressed}}</span>
                </td>
                <td>
                    <a (click)="EditUserDetails(item)" class="m-r-5" nz-button nzType="default" nzShape="circle"
                        nz-tooltip nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>


<nz-modal nzCentered nzOkText="Save" nzCancelText="Back" [(nzVisible)]="EditDetails" nzTitle="Edit Details"
    (nzOnOk)="saveChanges()" (nzOnCancel)="handleCancel()" [nzOkLoading]="isOkLoading">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-new-user></app-add-new-user> -->
        <div class="row main-row">
            <div class="col-lg-10">
                <form nz-form>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Remarks</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <!-- <input type="text" [(ngModel)]="remarks" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Remarks here"> -->
                                <textarea [(ngModel)]="remarks" [ngModelOptions]="{standalone: true}" rows="4" nz-input
                                    placeholder="Enter Remarks here"></textarea>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">Addressed</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <nz-switch [(ngModel)]="addressed" [ngModelOptions]="{standalone: true}"></nz-switch>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">Responded</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <nz-switch [(ngModel)]="responded" [ngModelOptions]="{standalone: true}"></nz-switch>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                </form>
            </div>
        </div>
    </div>
</nz-modal>


<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>