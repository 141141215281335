<nz-card>

    <div class="row  justify-content-between">
        <div class="col-lg-4 col-sm-5 col-12 mb-3">
            <div class="d-md-flex">
                <h2> User Management</h2>
            </div>
        </div>
        <div class="col-lg-4 col-sm-5 col-12 text-right">
            <div class="search-input-section">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                    <input type="text" [(ngModel)]="searchFilter" [ngModelOptions]="{standalone: true}" nz-input
                        placeholder="Search User" />
                </nz-input-group>

                <ng-template #suffixButton>
                    <i nz-icon nzType="search" class="opacity-05 search-icon-section"></i>
                </ng-template>
            </div>
        </div>
        <div class="col-lg-3 col-3 text-right add-button-section">
            <a (click)="addNewUser()" nz-button nzType="primary" class="add-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Add User</span>
            </a>
        </div>
    </div>

    <nz-table #userListTable [nzData]="userList" [nzShowPagination]="false" [nzScroll]="{x: '2000px' , y: tableWidth }">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of userList |filter: searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.name}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.primaryContactNo}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.email}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.role}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.location.locationName}}
                    </div>
                </td>
                <!-- <td>
                    <div class="d-flex align-items-center">
                        {{item.status}}
                    </div>
                </td> -->
                <td>
                    <nz-badge *ngIf="item.isActive == true" nzStatus="success"></nz-badge>
                    <nz-badge *ngIf="item.isActive == false" nzStatus="error"></nz-badge>
                    <span class="text-capitalize">{{item.isActive ? 'Active' : 'Inactive'}}</span>
                </td>
                <td>
                    <a (click)="EditUser(item)" class="m-r-5" nz-button nzType="default" nzShape="circle" nz-tooltip
                        nzTooltipTitle="Edit">
                        <i nz-icon nzType="edit" theme="outline"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<!-- MODAL SECTION STARTS -->

<nz-modal class="image-add-section" [nzStyle]="{top : '50px'}" [(nzVisible)]="AddUser"
    nzTitle="{{isEdit ? 'Edit User Details' : 'Add New User'}} " (nzOnCancel)="handleCancel()"
    [nzOkLoading]="isOkLoading">
    <div class="offer-item-details container-fluid" *nzModalContent>
        <!-- <app-add-new-user></app-add-new-user> -->
        <div class="row main-row">
            <div class="col-lg-10">
                <form nz-form>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Name</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Enter User Name">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <!-- <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input website!">
                        <input nz-input id="website" formControlName="email" placeholder="website" />
                    </nz-form-control> -->

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Mobile Number</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <!-- (keyup)="sendUserData()" -->
                                <input name="text" nz-input type="number" [(ngModel)]="mobNo"
                                    [ngModelOptions]="{standalone: true}" pattern="\d*" maxlength="10" required
                                    class="form-control" placeholder="Enter Mobile Number"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                <!-- <input type="number" [(ngModel)]="mobNo" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Mobile Number"> -->
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Email Id</nz-form-label>
                        <nz-form-control nzErrorTip="Invalid email address">
                            <nz-input-group>
                                <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    [(ngModel)]="email" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Enter Email">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Role</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch [(ngModel)]="role" [ngModelOptions]="{standalone: true}"
                                    class="w-100" nzPlaceHolder="Select User Role">
                                    <!-- <nz-option nzValue="Select" nzLabel="Select"></nz-option> -->
                                    <nz-option nzValue="Super Admin" nzLabel="Super Admin"></nz-option>
                                    <nz-option nzValue="Sub Admin" nzLabel="Sub Admin"></nz-option>
                                    <nz-option nzValue="Offer Maker" nzLabel="Offer Maker"></nz-option>
                                    <nz-option nzValue="Offer Admin" nzLabel="Offer Admin"></nz-option>
                                    <nz-option nzValue="CSR (Customer Service Representatives)"
                                        nzLabel="CRM Executive"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="role != 'Super Admin'">
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired>Location</nz-form-label>
                        <nz-form-control>
                            <nz-input-group>
                                <nz-select nzShowSearch [(ngModel)]="location" [ngModelOptions]="{standalone: true}"
                                    class="w-100" nzPlaceHolder="None">
                                    <nz-option *ngFor="let location of locationlist" [nzValue]="location.locationName"
                                        [nzLabel]="location.locationName"></nz-option>
                                </nz-select>
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Location</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="location" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Location">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <!-- <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Status</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" nz-input placeholder="Enter Status">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item> -->

                    <nz-form-item>
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandName">Password</nz-form-label>
                        <nz-form-control nzErrorTip="Please input your username!">
                            <nz-input-group>
                                <input type="text" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" nz-input
                                    placeholder="Password">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item *ngIf="isEdit==true">
                        <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="BrandActive">Suspend The
                            User</nz-form-label>
                        <p class="suspend_action" (click)="showAlert()">{{isActive ? "Suspend" : "Unsuspend"}}</p>
                        <!-- <nz-form-control>
                            <nz-input-group>
                                <nz-switch id="BrandActive" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}" [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
                                <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
                                <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
                            </nz-input-group>
                        </nz-form-control> -->
                    </nz-form-item>
                    <!-- <button style="justify-content: center;" [ngModelOptions]="{standalone: true}" [ngClass]="{'SuspendedButton' : !isActive}" nz-button nzType="default"><span *ngIf="isActive">Suspend</span><span *ngIf="!isActive">Suspended</span></button> -->

                </form>

            </div>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">Back</button>
        <button nz-button nzType="primary" (click)="sendUserData()" [nzLoading]="isOkLoading">
            {{isEdit ? 'Update' : 'Add'}}
        </button>
    </div>
</nz-modal>


<nz-modal nzCentered nzWidth="350px !important" nzCentered class="alert-modal" nzOkText="Yes" nzCancelText="No"
    [(nzVisible)]="AlertMessage" nzTitle="Alert" (nzOnCancel)="alertCancel()" (nzOnOk)="alertSuccess()"
    [nzOkLoading]="isOkLoading">
    <div class="text-left" *nzModalContent>
        <h4>Are You Sure want to Suspend the Member</h4>
    </div>
</nz-modal>

<!-- (nzOnOk)="sendUserData()" -->

<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>