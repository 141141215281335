import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-store-location',
  templateUrl: './add-store-location.component.html',
  styleUrls: ['./add-store-location.component.css'],
})
export class AddStoreLocationComponent implements OnInit {
  MediaPage = false;
  isOkLoading=false;

  OpeningTime: Date | null = null;
  closingTime: Date | null = null;
  

  constructor() {}

  ngOnInit(): void {}

  handleCancel(){
    this.MediaPage=false
  }

  showMedia(){
    this.MediaPage=true
  }
}
