<nz-card>
    <div class="row  justify-content-between">
        <div class="col-xl-6 col-lg-6 col-12 col-md-5 col-sm-5 mb-3">
            <div class="d-md-flex">
                <!-- <div class="m-b-10 m-r-20 d-flex align-items-center">
                    <nz-select style="min-width: 220px;" class="w-100" nzPlaceHolder="Single Product Offer">
                        <nz-option nzLabel="Single Product Offer" nzValue="Single Product Offer"></nz-option>
                        <nz-option nzLabel="Combo Offer" nzValue="Combo Offer"></nz-option>
                    </nz-select>
                </div> -->
                <h2>Single Product Offer</h2>
            </div>
        </div>
        <div class="col-lg-3 col-3 text-right add-button-section">
            <a (click)="syncProducts()" nz-button nzType="primary" class="add-button">
                <i nz-icon nzType="plus-circle" theme="outline"></i>
                <span>Sync Products</span>
            </a>
        </div>
        <div class="col-xl-4 col-md-5 col-sm-5 col-12 mb-3">
           
            <div class="row justify-content-between">
                <div class="col-12 text-right">
                   
                    <div class="search-input-section">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                            <input type="text" [(ngModel)]="searchFilter" (keyup)="clearCheck()"
                                (keyup.enter)="searchProducts()" nz-input placeholder="search by item name" />
                        </nz-input-group>

                        <ng-template #suffixButton>
                            <i (click)="searchProducts()" nz-icon nzType="search"
                                class="opacity-05 search-icon-section"></i>
                        </ng-template>
                    </div>
                </div>
                <!-- <div class="col-lg-5 col-3 text-right add-button-section">
                    <a class="publish-button" (click)="publishAll()" nz-button nzType="" [ngClass]="{'unpublish-button': !PublishStatus}">
                        <i *ngIf="PublishStatus" nz-icon>Publish All</i>
                        <i *ngIf="!PublishStatus" nz-icon>UnPublish All</i>
                        <span *ngIf="PublishStatus">Publish All</span>
                        <span *ngIf="!PublishStatus">UnPublish All</span>

                    </a>
                </div> -->
            </div>
        </div>
        <div *ngIf="checkboxActive" class="col-xl-3 col-lg-6 col-md-6 col-8 mb-3">
            <!-- <a class="publish-button" nz-button nzType=""><span>Publish Selected</span></a> -->
            <div class="">
                <nz-select nzShowSearch [(ngModel)]="selectedOption" class="w-100" nzPlaceHolder="None">
                    <nz-option nzValue=true nzLabel="Publish"></nz-option>
                    <nz-option nzValue=false nzLabel="Unpublish"></nz-option>
                </nz-select>
            </div>
        </div>
        <div *ngIf="checkboxActive" class="col-xl-1 col-lg-6 col-md-6 col-4 checkboxColumn">
            <a class="publish-button" (click)="publishAll()" nz-button nzType=""><span>Submit</span></a>
        </div>
    </div>


    <!-- <nz-table #productsListTable [nzData]="productsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of productsList |filter:searchFilter;  let i=index">
                <td>{{i+1}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemCode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemName}}
                    </div>
                </td>
                <td>
                    <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.productImage"></nz-avatar>
                </td>
                <td>
                    <div class="d-flex align-items-center date-field">
                        {{item.offerStart}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center date-field">
                        {{item.offerEnd}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.totalMrp}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.totalOfferPrice}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerPercentage}}
                    </div>
                </td>
            </tr>
        </tbody>
    </nz-table> -->

    <nz-table #productsListTable [nzData]="productsList" [nzFrontPagination]="false" [nzTotal]="totalCount"
        [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" (nzQueryParams)="onPageNumberChange('offersTable' , $event)"
        [nzScroll]="{x: '2000px' , y: tableWidth }">
        <thead>
            <tr>
                <th nzWidth="50px">
                    <label class="checkbox-label" (ngModelChange)="SelectAll()" [(ngModel)]="Checked"
                        nz-checkbox></label>
                    <!-- <span>Status</span> -->
                </th>
                <th *ngFor="let column of orderColumn" [nzWidth]="column.width" [nzSortFn]="column.compare">{{
                    column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr id="offersTable" *ngFor="let item of productsListTable.data   let i=index">
                <td>
                    <label class="checkbox-label" (ngModelChange)="checkSelected(item)" nz-checkbox
                        [(ngModel)]="item.isSelected"></label>
                </td>
                <td>{{((pageIndex-1) * pageSize) + (i+1)}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemCode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-badge [nzStatus]="item.isPublished ? 'success' : 'error'"></nz-badge>
                        <span *ngIf="!item.isPublished">Un Published</span>
                        <span *ngIf="item.isPublished">Published</span>
                    </div>
                </td>
                <td>
                    <nz-avatar (click)="showMedia(item.productId)" nzShape="square" [nzSize]="80" nzIcon="picture"
                        [nzSrc]="item.productImage"></nz-avatar>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.offerStart}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center ">
                        {{item.offerEnd}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.totalMrp}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.totalOfferPrice}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.offerPercentage}}
                    </div>
                </td>
                <!-- <td>
                    <div (click)="changeStatus(item)" class="d-flex align-items-center">
                        <a nz-button nzType="" class="publish-button" [ngClass]="{'unpublish-button' : !item.isPublished}">
                            <span *ngIf="item.isPublished==false">unPublish</span>
                            <span *ngIf="item.isPublished==true">Published</span>
                        </a>
                    </div>
                </td> -->
            </tr>
        </tbody>
    </nz-table>
</nz-card>

<nz-modal nzCentered class="image-add-section" [(nzVisible)]="MediaPage" nzTitle="Add Image"
    (nzOnCancel)="handleCancel()" (nzOnOk)="sendMedia()" [nzOkLoading]="isOkLoading">
    <div class="media-section container-fluid" *nzModalContent>
        <app-media-page></app-media-page>
    </div>
</nz-modal>

<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>