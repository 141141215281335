import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html',
})
export class SideNavComponent {
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  userRole = '';

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('userRole');

  constructor(
    private themeService: ThemeConstantService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('userRole');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    this.userRole = token;
  }

  closeMobileMenu(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 992) {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
      }
    }
  }
  checkAccess(item) {
    if (
      item.submenu.length === 0 &&
      item.acceptedUser.includes(this.userRole)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
