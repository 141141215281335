import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-store-location-page',
  templateUrl: './store-location-page.component.html',
  styleUrls: ['./store-location-page.component.css'],
})
export class StoreLocationPageComponent implements OnInit {
  AddLocation = false;
  isOkLoading = false;
  isEdit = false;

  getscreenwidth: any;
  tableWidth = '';

  MediaPage = false;
  locationlist: any = [];
  id = '';
  storeName = '';
  locationCode = '';
  locationName = '';
  googleMapUrl = '';
  address1 = '';
  address2 = '';
  city = '';
  pincode = '';
  email = '';
  mobileNo: any;
  openingTime: any;
  closingTime: any;
  imagePath: any;
  isActive = false;
  searchFilter = '';

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    self.spinner.show();
    axios
      .get(environment.url + 'store-locations/', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.locationlist = response.data.data.storeLocations.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              storeName: data.name,
              locationCode: data.locationCode,
              locationName: data.locationName,
              address1: data.address.line1,
              address2: data.address.line2,
              city: data.address.city,
              pincode: data.address.pincode,
              url: data.socialMediaLinks.googleMap,
              contact: data.contactData.phone,
              itemName: data.itemName,
              email: data.contactData.email,
              image: environment.url + '' + data.images[0], //data.images[0].image.url,
              active: data.isActive,
              timingOpen: data.shopTimings.opening,
              timingClose: data.shopTimings.closing,
            };
          }
        );
        self.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }
  clearData() {
    var self = this;
    self.storeName = '';
    self.locationCode = '';
    self.locationName = '';
    self.googleMapUrl = '';
    self.address1 = '';
    self.address2 = '';
    self.city = '';
    self.pincode = '';
    self.email = '';
    self.mobileNo = '';
    self.openingTime = undefined;
    self.closingTime = undefined;
    self.imagePath = undefined;
    self.isActive = true;
  }

  orderColumn = [
    {
      width: '80px',
      title: 'S.No',
    },
    {
      width: '150px',
      title: 'Store Name',
    },
    {
      width: '150px',
      title: 'image',
    },
    {
      width: '150px',
      title: 'Location Code',
    },
    {
      width: '150px',
      title: 'Location name',
    },
    {
      width: '150px',
      title: 'Address1',
    },
    {
      width: '150px',
      title: 'Address2',
    },
    {
      width: '150px',
      title: 'city',
    },
    {
      width: '150px',
      title: 'pincode',
    },
    {
      width: '150px',
      title: 'Google Map URL',
    },
    {
      width: '150px',
      title: 'Contact Number',
    },
    {
      width: '150px',
      title: 'Email',
    },
    {
      width: '200px',
      title: 'Opening - Closing Time',
    },
    // {
    // width:'150px',
    //   title: 'Status',
    // },
    {
      width: '120px',
      title: 'Active',
    },
    {
      width: '100px',
      title: 'Action',
    },
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.fetchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  handleCancel() {
    this.AddLocation = false;
    this.MediaPage = false;
  }

  addLocation() {
    this.clearData();
    this.AddLocation = true;
    this.isActive = true;
    this.isEdit = false;
  }

  showMedia() {
    this.MediaPage = true;
  }
  uploadImage(event) {
    var self = this;
    console.log(event.target.files);
    self.imagePath = event.target.files;
  }
  editChanges(item) {
    var self = this;
    self.AddLocation = true;
    self.id = item.id;
    self.storeName = item.storeName;
    self.locationCode = item.locationCode;
    self.locationName = item.locationName;
    self.googleMapUrl = item.url;
    self.address1 = item.address1;
    self.address2 = item.address2;
    self.city = item.city;
    self.pincode = item.pincode;
    self.email = item.email;
    self.mobileNo = item.contact;
    self.openingTime = moment(item.timingOpen, 'LT').format(); //item.timingOpen;
    self.closingTime = moment(item.timingClose, 'LT').format(); //item.timingClose;
    self.imagePath = item.image;
    self.isActive = item.active;
    self.isEdit = true;
  }
  doChanges() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    console.log(this.imagePath);
    if (self.storeName == '') {
      self.toastr.error('', 'Enter the Store Name', {
        timeOut: 2500,
      });
    } else if (self.AddLocation != true && self.imagePath == undefined) {
      self.toastr.error('', 'Select the Image', {
        timeOut: 2500,
      });
    } else if (self.locationCode == '') {
      self.toastr.error('', 'Enter the Location Code', {
        timeOut: 2500,
      });
    } else if (self.locationName == '') {
      self.toastr.error('', 'Enter the Location Code', {
        timeOut: 2500,
      });
      alert('x');
    } else if (self.googleMapUrl == '') {
      self.toastr.error('', 'Enter the Google Url', {
        timeOut: 2500,
      });
    } else if (self.address1 == '') {
      self.toastr.error('', 'Enter the Address', {
        timeOut: 2500,
      });
    } else if (self.city == '') {
      self.toastr.error('', 'Enter the city', {
        timeOut: 2500,
      });
    } else if (self.pincode == '') {
      self.toastr.error('', 'Enter the Pincode', {
        timeOut: 2500,
      });
    } else if (self.email == '') {
      self.toastr.error('', 'Enter the Email', {
        timeOut: 2500,
      });
    } else if (self.mobileNo == undefined) {
      self.toastr.error('', 'Enter the Mobile Number', {
        timeOut: 2500,
      });
    } else if (self.openingTime == undefined) {
      self.toastr.error('', 'Select the Opening Time', {
        timeOut: 2500,
      });
    } else if (self.closingTime == undefined) {
      self.toastr.error('', 'Select the Closing Time', {
        timeOut: 2500,
      });
    } else {
      console.log(moment(self.openingTime).format('LT'));
      if (self.isEdit == false) {
        var bodyFormData = new FormData();
        bodyFormData.append('name', self.storeName);
        bodyFormData.append('locationCode', self.locationCode);
        bodyFormData.append('locationName', self.locationName);
        bodyFormData.append('socialMediaLinks[googleMap]', self.googleMapUrl);
        bodyFormData.append('address[line1]', self.address1);
        bodyFormData.append('address[line2]', self.address2);
        bodyFormData.append('address[city]', self.city);
        bodyFormData.append('address[pincode]', self.pincode);
        bodyFormData.append(
          'shopTimings[opening]',
          moment(self.openingTime).format('LT')
        );
        bodyFormData.append(
          'shopTimings[closing]',
          moment(self.closingTime).format('LT')
        );
        bodyFormData.append('isActive', self.isActive.toString());
        bodyFormData.append('contactData[phone]', self.mobileNo.toString());
        bodyFormData.append('contactData[email]', self.email);
        bodyFormData.append('contactNumber', self.mobileNo);
        for (var i = 0; i < self.imagePath.length; i++) {
          bodyFormData.append('images', self.imagePath[i]);
        }

        axios({
          method: 'post',
          url: environment.url + 'store-locations/create',
          data: bodyFormData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
          },
        })
          .then((response) => {
            //handle success
            console.log(response);
            if (response.data.status == 'success') {
              self.toastr.success(response.data.message, '', {
                timeOut: 2500,
              });
              self.fetchData();
              self.handleCancel();
              self.clearData();
            }
          })
          .catch((error) => {
            //handle error
            console.log(error.response);
            self.toastr.error('', error.response.data.message, {
              timeOut: 2500,
            });
          });
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append('storeLocationId', self.id);
        bodyFormData.append('name', self.storeName);
        bodyFormData.append('locationCode', self.locationCode);
        bodyFormData.append('locationName', self.locationName);
        bodyFormData.append('socialMediaLinks[googleMap]', self.googleMapUrl);
        bodyFormData.append('address[line1]', self.address1);
        bodyFormData.append('address[line2]', self.address2);
        bodyFormData.append('address[city]', self.city);
        bodyFormData.append('address[pincode]', self.pincode);
        bodyFormData.append(
          'shopTimings[opening]',
          moment(self.openingTime).format('LT')
        );
        bodyFormData.append(
          'shopTimings[closing]',
          moment(self.closingTime).format('LT')
        );
        bodyFormData.append('isActive', self.isActive.toString());
        bodyFormData.append('contactData[phone]', self.mobileNo.toString());
        bodyFormData.append('contactData[email]', self.email);
        bodyFormData.append('contactNumber', self.mobileNo);
        for (var i = 0; i < self.imagePath.length; i++) {
          bodyFormData.append('images', self.imagePath[i]);
        }

        axios({
          method: 'post',
          url: environment.url + 'store-locations/update',
          data: bodyFormData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
          },
        })
          .then((response) => {
            //handle success
            console.log(response);
            if (response.data.status == 'success') {
              self.toastr.success(response.data.message, '', {
                timeOut: 2500,
              });
              self.clearData();
              self.handleCancel();
              self.fetchData();
            }
          })
          .catch((error) => {
            //handle error
            console.log(error);
            console.log(error.response);
            self.toastr.error('', error.response.data.message, {
              timeOut: 2500,
            });
          });
      }
    }
  }
}
