import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import axios, { Axios } from 'axios';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NavService } from '../../services/nav.service';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css'],
})
export class UserPageComponent implements OnInit {
  AddUser = false;
  isEdit = false;
  isOkLoading = false;
  userList = [];
  userDetails: any;
  searchFilter = '';

  getscreenwidth: any;
  tableWidth = '';

  AlertMessage = false;

  validateForm!: FormGroup;

  userName = '';
  mobNo: any;
  email = '';
  role = '';
  location = '';
  password = '';
  isActive = false;
  id = '';

  suspendStatus = false;

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    var type = {
      userType: 'Admin',
    };
    axios
      .post(environment.url + 'auth/get-all-users', type, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        self.userList = response.data.data;
        this.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  clearData() {
    var self = this;
    self.userName = '';
    self.mobNo = null;
    self.email = '';
    self.role = '';
    self.location = '';
    self.password = '';
    self.isActive = false;
    self.id = '';
  }

  orderColumn = [
    {
      width: '50px',
      title: 'S.No',
    },
    {
      width: '150px',
      title: 'Name',
    },
    {
      width: '150px',
      title: 'Mobile Number',
    },
    {
      width: '150px',
      title: 'Email id',
    },
    {
      width: '150px',
      title: 'Role',
    },
    {
      width: '150px',
      title: 'Location',
    },
    // {
    // width:'150px',
    //   title: 'Status',
    // },
    {
      width: '100px',
      title: 'Active',
    },
    {
      width: '100px',
      title: 'Action',
    },
  ];

  constructor(
    public navService: NavService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  locationlist: any;

  ngOnInit(): void {
    // get the locations for create user location
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    this.spinner.show();
    axios
      .get(environment.url + 'store-locations/', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        this.locationlist = response.data.data.storeLocations.map(
          (data: any, index: number) => {
            return {
              locationName: data.locationName,
            };
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.spinner.hide();
        //alert(error.response.data.message);
      });

    this.fetchData();

    // this.validateForm = this.fb.group({
    //   email: [null, [Validators.email, Validators.required]]
    // });

    this.getscreenwidth = window.innerWidth;
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  addNewUser() {
    this.AddUser = true;
    this.isEdit = false;
    this.clearData();
  }
  EditUser(item) {
    var self = this;
    this.AddUser = true;
    this.isEdit = true;
    this.userName = item.name;
    this.mobNo = item.primaryContactNo;
    this.email = item.email;
    this.role = item.role;
    this.location = item.location.locationName;
    this.id = item._id;
    this.isActive = item.isActive;
    //this.password = item.password
  }
  handleCancel() {
    this.AddUser = false;
  }
  sendUserData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    if (self.userName == '') {
      self.toastr.error('', 'Enter the User Name', {
        timeOut: 2500,
      });
    } else if (self.mobNo == '') {
      self.toastr.error('', 'Enter the Mobile Number', {
        timeOut: 2500,
      });
    } else if (self.email == '') {
      self.toastr.error('', 'Enter the Email', {
        timeOut: 2500,
      });
    } else if (self.role == '') {
      self.toastr.error('', 'Select the role', {
        timeOut: 2500,
      });
    } else if (self.location == '') {
      self.toastr.error('', 'Enter the Location', {
        timeOut: 2500,
      });
    } else if (self.password == '' && self.isEdit == false) {
      self.toastr.error('', 'Enter the Password', {
        timeOut: 2500,
      });
    } else {
      self.isOkLoading = true;
      if (self.isEdit == false) {
        let userDetails = {
          email: self.email,
          password: self.password,
          name: self.userName,
          primaryContactNo: self.mobNo,
          location: self.location,
          isActive: true,
          role: self.role,
        };
        console.log(userDetails);
        axios
          .post(environment.url + 'auth/create-admin-users', userDetails, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data);
              self.AddUser = false;
              self.isEdit = false;
              self.isOkLoading = false;
              self.toastr.success(response.data.message, '', {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearData();
              //  self.showTable = !self.showTable;
              //  self.showForm = !self.showForm;
              //  localStorage.setItem('Token',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            console.log(error.response);
            self.toastr.error(error.response.data.message, '', {
              timeOut: 2500,
            });
            self.isOkLoading = false;
            // let errorData = error.response.data.data;
            // let errorString = 'Please Check Below Error \n\n';
            // if (errorData) {
            //   for (let i = 0; i < errorData.length; i++) {
            //     errorString +=
            //       errorData[i].param + ' - ' + errorData[i].msg + '\n';
            //   }
            //   alert(errorString);
            // }
          });
      } else if (self.isEdit == true) {
        var userDetails = {
          adminId: self.id,
          email: self.email,
          password: self.password,
          name: self.userName,
          primaryContactNo: self.mobNo,
          location: self.location,
          isActive: self.isActive,
          role: self.role,
        };
        if (self.password == '') {
          delete userDetails.password;
        }

        console.log(userDetails);
        axios
          .post(environment.url + 'auth/update-admin-users', userDetails, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then((response) => {
            if (response.data.status == 'success') {
              console.log(response.data.data);
              self.AddUser = false;
              self.isEdit = false;
              self.isOkLoading = false;
              self.toastr.success(response.data.message, '', {
                timeOut: 2500,
              });
              self.fetchData();
              self.clearData();
              //  self.showTable = !self.showTable;
              //  self.showForm = !self.showForm;
              //  localStorage.setItem('Token',response.data.data);
              //  self.router.navigate(['dashboard'])
            }
          })
          .catch((error) => {
            console.log(error.response);
            self.toastr.error(error.response.data.message, '', {
              timeOut: 2500,
            });
            self.isOkLoading = false;
            // let errorData = error.response.data.data;
            // let errorString = 'Please Check Below Error \n\n';
            // if (errorData) {
            //   for (let i = 0; i < errorData.length; i++) {
            //     errorString +=
            //       errorData[i].param + ' - ' + errorData[i].msg + '\n';
            //   }
            //   alert(errorString);
            // }
          });
      }
    }
  }

  // doChanges() {
  //   var self = this;
  //   self.navService.formRefreshAnnouncedSource.subscribe(item => {
  //     console.log(item);
  //     self.userDetails = item;
  //     console.log(self.userDetails.name);
  //     console.log(self.userDetails.email);
  //     console.log(self.userDetails.password);
  //     console.log(self.userDetails.primaryContactNo);
  //     console.log(self.userDetails.location);
  //     console.log(self.userDetails.isActive);
  //     console.log(self.userDetails.role);
  //     if(!self.userDetails.name){
  //       alert('Enter the User Name');
  //     } else if(!self.userDetails.email){
  //       alert('Enter the Email');
  //     } else if(!self.userDetails.password){
  //       alert('Enter the Password');
  //     } else if(!self.userDetails.primaryContactNo){
  //       alert('Enter the Mobile Number');
  //     } else if(!self.userDetails.location){
  //       alert('Enter the Location');
  //     } else if(self.role == 'Select'){
  //       alert('Select the role');
  //     }else {
  //       self.handleCancel();
  //     }
  //     });

  // }

  changeSuspendStatus() {
    this.suspendStatus = !this.suspendStatus;
  }

  showAlert() {
    if (this.isActive == true) {
      this.AlertMessage = true;
    }
    this.isActive = !this.isActive;
  }

  alertCancel() {
    this.AlertMessage = false;
    this.isActive = true;
  }

  alertSuccess() {
    this.AlertMessage = false;
    this.isActive = false;
  }
}
