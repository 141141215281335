import { Component, OnInit } from '@angular/core';
import { NavService } from '../../services/nav.service';


@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.css']
})
export class AddNewUserComponent implements OnInit {
  
  userName = '';
  mobNo : any;
  email = '';
  role = 'Select';
  location = '';
  password = '';
  isActive = false;

  userDetails = {};

  constructor(public navService: NavService) { }

  ngOnInit(): void {
    // this.userDetails = {
    //    "email" : this.email,
    //    "password" : this.password,
    //    "name" : this.userName,
    //    "primaryContactNo" : this.mobNo,
    //    "location" : this.location,
    //    "isActive" : this.isActive,
    //    "role" : this.role
    // }
  }
  sendUserData(){
    var self = this;
    self.userDetails = {
      "email" : self.email,
      "password" : self.password,
      "name" : self.userName,
      "primaryContactNo" : self.mobNo,
      "location" : self.location,
      "isActive" : self.isActive,
      "role" : self.role
   }
   this.navService.publishFormRefresh(self.userDetails);
  }
}
