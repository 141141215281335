import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
// import { AttributesComponent } from './project/attributes/attributes.component';

// NZ IMPORTS

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
// import { ProjectComponent } from './project/project.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NgOtpInputModule } from 'ng-otp-input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import { AddAttributeFormsComponent } from './Forms/add-attribute-forms/add-attribute-forms.component';
import { AddBrandFormsComponent } from './Forms/add-brand-forms/add-brand-forms.component';
import { AddCategoryFormsComponent } from './Forms/add-category-forms/add-category-forms.component';
import { AddProductsFormsComponent } from './Forms/add-products-forms/add-products-forms.component';
import { AddBannersFormsComponent } from './Forms/add-banners-forms/add-banners-forms.component';
import { AddServiceFormsComponent } from './Forms/add-service-forms/add-service-forms.component';
import { AddSpecValueComponent } from './Forms/add-spec-value/add-spec-value.component';
import { AddSpecGroupComponent } from './Forms/add-spec-group/add-spec-group.component';
import { LoginPageComponent } from './KurinjiAdmin/login-page/login-page.component';
import { ForgotPasswordPageComponent } from './KurinjiAdmin/forgot-password-page/forgot-password-page.component';
import { DashboardPageComponent } from './KurinjiAdmin/dashboard-page/dashboard-page.component';
import { HomrpageComponent } from './KurinjiAdmin/homrpage/homrpage.component';
import { MediaPageComponent } from './KurinjiAdmin/media-page/media-page.component';
import { OfferPageComponent } from './KurinjiAdmin/offer-page/offer-page.component';
import { SingleProductOfferPageComponent } from './KurinjiAdmin/single-product-offer-page/single-product-offer-page.component';
import { ViewOfferItemsPageComponent } from './KurinjiAdmin/view-offer-items-page/view-offer-items-page.component';
import { AddOfferItemComponent } from './KurinjiAdmin/add-offer-item/add-offer-item.component';
import { ProductsPageComponent } from './KurinjiAdmin/products-page/products-page.component';
import { UserPageComponent } from './KurinjiAdmin/user-page/user-page.component';
import { AddNewUserComponent } from './KurinjiAdmin/add-new-user/add-new-user.component';
import { UserReferralComponent } from './KurinjiAdmin/user-referral/user-referral.component';
import { StoreLocationPageComponent } from './KurinjiAdmin/store-location-page/store-location-page.component';
import { AddStoreLocationComponent } from './KurinjiAdmin/add-store-location/add-store-location.component';
import { FeedbackPageComponent } from './KurinjiAdmin/feedback-page/feedback-page.component';
import { CustomerPageComponent } from './KurinjiAdmin/customer-page/customer-page.component';
import { SettingPageComponent } from './KurinjiAdmin/setting-page/setting-page.component';
import { AddCustomerPageComponent } from './KurinjiAdmin/add-customer-page/add-customer-page.component';
import { BannerPageComponent } from './KurinjiAdmin/banner-page/banner-page.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent,
    AddAttributeFormsComponent,
    AddBrandFormsComponent,
    AddCategoryFormsComponent,
    AddProductsFormsComponent,
    AddBannersFormsComponent,
    AddServiceFormsComponent,
    AddSpecValueComponent,
    AddSpecGroupComponent,
    LoginPageComponent,
    ForgotPasswordPageComponent,
    DashboardPageComponent,
    HomrpageComponent,
    MediaPageComponent,
    OfferPageComponent,
    SingleProductOfferPageComponent,
    ViewOfferItemsPageComponent,
    AddOfferItemComponent,
    ProductsPageComponent,
    UserPageComponent,
    AddNewUserComponent,
    UserReferralComponent,
    StoreLocationPageComponent,
    AddStoreLocationComponent,
    FeedbackPageComponent,
    CustomerPageComponent,
    SettingPageComponent,
    AddCustomerPageComponent,
    BannerPageComponent,
    CustomerDetailsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    NzButtonModule,
    NzCardModule,
    NzAvatarModule,
    NzRateModule,
    NzBadgeModule,
    NzProgressModule,
    NzRadioModule,
    NzTableModule,
    NzDropDownModule,
    NzTimelineModule,
    NzTabsModule,
    NzTagModule,
    NzListModule,
    NzCalendarModule,
    NzToolTipModule,
    NzCheckboxModule,
    NzModalModule,
    NzUploadModule,
    NzMessageModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzDatePickerModule,
    NzPaginationModule,
    NzSwitchModule,
    NgOtpInputModule,
    NzTimePickerModule,
    NzPopoverModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    ThemeConstantService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
