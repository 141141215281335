import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.css'],
})
export class FeedbackPageComponent implements OnInit {
  moment: any = moment;
  getscreenwidth: any;
  tableWidth = '';
  EditDetails = false;
  isOkLoading = false;
  remarks: '';
  addressed: false;
  responded: false;
  reqId: '';

  FeedbackList = [];
  orderColumn = [
    {
      width: '80px',
      title: 'S.NO',
    },
    {
      width: '150px',
      title: 'Type',
    },
    {
      width: '150px',
      title: 'Image',
    },
    {
      width: '250px',
      title: 'Description',
    },
    {
      width: '200px',
      title: 'Member Name',
    },
    {
      width: '180px',
      title: 'Date & Time',
    },
    {
      width: '150px',
      title: 'Member Mobile',
    },
    {
      width: '200px',
      title: 'Remarks',
    },
    {
      width: '150px',
      title: 'Responded',
    },
    {
      width: '150px',
      title: 'Addressed',
    },
    {
      width: '100px',
      title: 'Action',
    },
  ];

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    self.spinner.show();
    axios
      .get(
        environment.url + 'sfm/',

        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        this.FeedbackList = response.data.data.suggestionsAndFeedback.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              description: data.description,
              image: environment.url + '' + data.image,
              name: data.createdBy.name,
              date: data.createdAt,
              type: data.type,
              mobileNumber: data.createdBy.primaryContactNo,
              responded: data.isResponded,
              addressed: data.isAddressed,
              remarks: data.remarks,
            };
          }
        );
        self.spinner.hide();
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  constructor(
    private spinner: NgxSpinnerService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.fetchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  RespondedStatus(item) {
    console.log('responded');
  }
  AddressedStatus(item) {
    console.log('addressed');
  }

  handleCancel() {
    this.EditDetails = false;
  }
  EditUserDetails(item) {
    console.log(item);
    this.EditDetails = true;
    this.reqId = item.id;
    this.remarks = item.remarks;
    this.addressed = item.addressed;
    this.responded = item.responded;
  }

  saveChanges() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    axios
      .post(
        environment.url + 'sfm/update',
        {
          id: this.reqId,
          isResponded: this.responded,
          isAddressed: this.addressed,
          remarks: this.remarks,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        this.handleCancel();
        this.EditDetails = false;
        this.fetchData();

        // this.productsList = response.data.data;
        //this.attributeList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        //alert(error.response.data.message);
      });
  }
}
