import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.css'],
})
export class OfferPageComponent implements OnInit {
  DetailsShow = false;
  AddImage = false;
  isOkLoading = false;

  PublishStatus = true;

  Checked = false;
  checkboxActive = false;
  tableWidth = '';
  getscreenwidth: any;

  selectedOption = 'Publish Selected';

  checkedList = [];

  orderColumn = [
    {
      width: '80px',
      title: 'S.NO',
    },
    {
      width: '150px',
      title: 'Status',
    },
    {
      width: '150px',
      title: 'Offer Name',
    },
    {
      width: '150px',
      title: 'Offer code',
    },
    {
      width: '150px',
      title: 'Offer Item',
    },
    {
      width: '150px',
      title: 'Product Image',
    },
    {
      width: '150px',
      title: 'Offer start date',
    },
    {
      width: '150px',
      title: 'Offer end date',
    },
    {
      width: '150px',
      title: 'Total Mrp Price (Rs.)',
    },
    {
      width: '180px',
      title: 'Total Offer Price (Rs.)',
    },
    {
      width: '150px',
      title: 'Total Offer Discount Percentage (%)',
    },
    {
      width: '150px',
      title: 'Banner Image',
    },
    // {
    //   width:'150px',
    //   title: 'Action',
    // },
  ];

  productsList = [
    {
      offerName: 'Ramzan offer',
      offerCode: '123456',
      productImage: '../../../assets/AdminPanel/Media/Image 15.png',
      offerStart: 'dd/mm/yyyy',
      offerEnd: 'dd/mm/yyyy',
      totalMrp: '77.99',
      totalOfferPrice: '70.99',
      offerPercentage: '15.00',
      isSelected: false,
      id: 57,
      isPublished: true,
    },
    {
      offerName: 'Ramzan offer',
      offerCode: '123456',
      productImage: '../../../assets/AdminPanel/Media/Image 12.png',
      offerStart: 'dd/mm/yyyy',
      offerEnd: 'dd/mm/yyyy',
      totalMrp: '77.99',
      totalOfferPrice: '70.99',
      offerPercentage: '15.00',
      isSelected: false,
      id: 58,
      isPublished: true,
    },
    {
      offerName: 'Ramzan offer',
      offerCode: '123456',
      productImage: '../../../assets/AdminPanel/Media/Image 13.png',
      offerStart: 'dd/mm/yyyy',
      offerEnd: 'dd/mm/yyyy',
      totalMrp: '77.99',
      totalOfferPrice: '70.99',
      offerPercentage: '15.00',
      isSelected: true,
      id: 59,
      isPublished: true,
    },
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  showComboDetails() {
    this.DetailsShow = true;
  }
  showAddImage() {
    this.AddImage = true;
  }

  handleCancel(): void {
    this.DetailsShow = false;
    this.AddImage = false;
  }

  publishAll() {
    this.PublishStatus = !this.PublishStatus;
  }

  SelectAll() {
    for (let i = 0; i < this.productsList.length; i++) {
      if (this.Checked == false) {
        this.productsList[i].isSelected = true;
        this.checkedList.push(this.productsList[i].id);
      } else if (this.Checked == true) {
        this.productsList[i].isSelected = false;
        this.checkedList = [];
      }
    }
    // console.log(this.checkedList);
    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }

  checkSelected(item) {
    item.isSelected = !item.isSelected;
    // console.log(item.isSelected);
    if (item.isSelected == true) {
      this.checkedList.push(item.id);
      // console.log(this.checkedList);
    } else if (item.isSelected == false) {
      this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
      // console.log(this.checkedList);
    }

    if (this.checkedList.length >= 1) {
      this.checkboxActive = true;
    } else if (this.checkedList.length == 0) {
      this.checkboxActive = false;
    }
  }
}
