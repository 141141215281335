import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-offer-items-page',
  templateUrl: './view-offer-items-page.component.html',
  styleUrls: ['./view-offer-items-page.component.css'],
})
export class ViewOfferItemsPageComponent implements OnInit {
  orderColumn = [
    {
      title: 'Item code',
    },
    {
      title: 'Item name',
    },
    {
      title: 'Product Image',
    },
    {
      title: 'Mrp Price',
    },
  ];

  OfferItemsList = [
    {
      itemCode:'#123456',
      itemName:'Gold Winner Refined Sunflower Oil Pouch',
      image:'../../../assets/AdminPanel/Media/Image 15.png',
      price:'77.99'
    },
    {
      itemCode:'#123456',
      itemName:'Gold Winner Refined Sunflower Oil Pouch',
      image:'../../../assets/AdminPanel/Media/Image 15.png',
      price:'77.99'
    },
    {
      itemCode:'#123456',
      itemName:'Gold Winner Refined Sunflower Oil Pouch',
      image:'../../../assets/AdminPanel/Media/Image 15.png',
      price:'77.99'
    }
  ];

  constructor() {}

  ngOnInit(): void {}
}
