<nz-card>
    <div class="row m-b-30">
        <div class="col-lg-8">
            <div class="d-md-flex">
                <h2>Settings</h2>
            </div>
        </div>
    </div>

    <div class="row ">
        <div class="col-lg-10">
            <form nz-form>
                <!-- <nz-form-item>
                    <nz-form-label [nzSm]="4" nzRequired nzFor="BrandName">Bill history</nz-form-label>
                    <nz-form-control nzErrorTip="Please input your username!">
                        <nz-input-group>
                            <input type="text" nz-input placeholder="Enter Bill history">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item> -->
                <nz-form-item>
                    <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">E-Commerce </nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter E-Commerce Link!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="ecommerceLink" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter E-Commerce Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">About us </nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter About us Link!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="aboutUsLink" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter About us Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">Privacy Policy</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Privacy Policy Link!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="privacyPolicyLink" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Privacy Policy Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">Share App</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Share App link!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="appLink" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Share App Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">Rate Us</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Rate Us Link!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="rateLink" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter Rate Us Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSm]="4" [nzMd]="8" nzRequired nzFor="">FAQ</nz-form-label>
                    <nz-form-control nzErrorTip="Please Enter Faq Link!">
                        <nz-input-group>
                            <input type="text" [(ngModel)]="faqLink" [ngModelOptions]="{standalone: true}" nz-input placeholder="Enter FAQ Link">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>


                <!-- <nz-form-item class="">
                    <nz-form-control>
                        <button type="button" class="mr-5" nz-button nzType="default">Update</button>
                    </nz-form-control>
                </nz-form-item> -->

            </form>

        </div>

    </div>
    <div class="text-center">
        <button type=" button" (click)="updateSettings()" class="mr-5 add-button" nz-button nzType="default ">Update</button>
    </div>

</nz-card>


<ngx-spinner bdColor="#000000e0" size="medium" color="#ff0003" type="ball-fall" [fullScreen]="true">
</ngx-spinner>