<nz-card>
    <nz-table #OfferItemsListTable [nzData]="OfferItemsList">
        <thead>
            <tr>
                <th *ngFor="let column of orderColumn" [nzSortFn]="column.compare">{{ column.title }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of OfferItemsListTable.data;  let i=index">
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemCode}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        {{item.itemName}}
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <nz-avatar nzShape="square" [nzSize]="80" nzIcon="picture" [nzSrc]="item.image"></nz-avatar>
                    </div>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        Rs. {{item.price}}
                    </div>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>