import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import axios, { Axios } from 'axios';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import {
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.css'],
})
export class CustomerPageComponent implements OnInit {
  moment: any = moment;

  getscreenwidth: any;
  tableWidth = '';

  AddUser = false;
  isOkLoading = false;
  CustomerList = [];
  religion = 'Select';
  anniversaryDate = '';
  maritialStatus = 'Select';
  customerId = '';
  searchFilter = '';
  isActive = false;
  suspendStatus = false;

  AlertMessage = false;

  MY_DATA_KEY: StateKey<string> = makeStateKey<string>('Token');

  constructor(
    private spinner: NgxSpinnerService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  fetchData() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    var self = this;
    self.spinner.show();
    var type = {
      userType: 'Customer',
    };
    axios
      .post(environment.url + 'auth/get-all-users', type, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        console.log(response.data);
        //this.CustomerList = response.data.data;
        this.CustomerList = response.data.data.map(
          (data: any, index: number) => {
            return {
              id: data._id,
              memberId: 'TN1' + data.memberId,
              name: data.name,
              gender: data.gender,
              profileImage: environment.url + '' + data.profileImage, //data.images[0].image.url,
              dateOfBirth:
                data.hasOwnProperty('dateOfBirth') == true &&
                data.dateOfBirth != null
                  ? data.dateOfBirth.split('T')[0]
                  : '',
              primaryContactNo: data.primaryContactNo,
              email: data.email,
              location: data.location.name,
              addressLine1: data.contactData.addressLine1,
              addressLine2: data.contactData.addressLine2,
              area: data.contactData.area,
              city: data.contactData.city,
              pincode: data.contactData.pincode,
              religion: data.religion,
              martialStatus: data.maritalStatus,
              anniversaryDate: data.anniversaryDate,
              isActive: data.isActive,
            };
          }
        );
        console.log(this.CustomerList[0].profileImage);
        //this.attributeList = response.data.data;
        self.spinner.hide();
      })
      .catch((error) => {
        console.log(error);
        self.spinner.hide();
        //alert(error.response.data.message);
      });
  }

  orderColumn = [
    {
      title: 'S.No',
      width: '80px',
    },
    {
      width: '150px',
      title: 'Member ID',
    },
    {
      width: '150px',
      title: 'Name',
    },
    {
      width: '150px',
      title: 'Gender',
    },
    {
      width: '180px',
      title: 'DOB',
    },
    {
      width: '150px',
      title: 'Mobile Number',
    },
    {
      width: '200px',
      title: 'Email',
    },
    {
      width: '150px',
      title: 'Address1',
    },
    {
      width: '250px',
      title: 'Address2',
    },
    {
      width: '150px',
      title: 'Area',
    },
    {
      width: '150px',
      title: 'City',
    },
    {
      width: '150px',
      title: 'Pincode',
    },
    {
      width: '150px',
      title: 'Religion',
    },
    {
      width: '150px',
      title: 'Marital Status',
    },
    {
      width: '220px',
      title: 'Anniversary Date ',
    },
    {
      width: '150px',
      title: 'IsActive ',
    },
    {
      width: '150px',
      title: 'Action ',
    },
  ];

  ngOnInit(): void {
    this.fetchData();
    if (isPlatformBrowser(this.platformId)) {
      this.getscreenwidth = window.innerWidth;
    }
    if (this.getscreenwidth > 1024) {
      this.tableWidth = '450px';
    } else {
      this.tableWidth = '300px';
    }
  }

  handleCancel() {
    this.AddUser = false;
  }

  editUser(item) {
    this.AddUser = true;
    console.log(item);
    this.customerId = item.id;
    this.isActive = item.isActive;
    this.maritialStatus = item.martialStatus;
    this.religion = item.religion;
    this.anniversaryDate = item.anniversaryDate;
  }

  addCustomerDetails() {
    var token: any = '';

    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('Token');
    } else if (this.transferState.hasKey(this.MY_DATA_KEY)) {
      token = this.transferState.get(this.MY_DATA_KEY, null);
    }

    // alert(this.religion);
    if (this.religion == 'Select') {
      alert('Select Religion');
    }
    // else if(this.anniversaryDate == ''){
    //   alert('Select Anniversary Date');
    // }
    else if (this.maritialStatus == 'Select') {
      alert('Select Maritial Status');
    } else {
      // alert('validated');
      // alert(this.anniversaryDate);
      // alert(this.customerId);
      var updateDetails = {
        customerId: this.customerId,
        religion: this.religion,
        maritalStatus: this.maritialStatus,
        anniversaryDate: moment(this.anniversaryDate),
      };
      axios
        .post(
          environment.url + 'auth/update-customer-profile-by-admin',
          updateDetails,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.handleCancel();
          this.fetchData();
          // this.productsList = response.data.data;
          //this.attributeList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          //alert(error.response.data.message);
        });
    }
  }

  changeSuspendStatus() {
    this.suspendStatus = !this.suspendStatus;
  }

  alertCancel() {
    this.AlertMessage = false;
    this.isActive = true;
  }

  alertSuccess() {
    this.AlertMessage = false;
    this.isActive = false;
  }

  showAlert() {
    if (this.isActive == true) {
      this.AlertMessage = true;
    }
    this.isActive = !this.isActive;
  }
}
